import React from "react";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { colors } from "@mui/material";

const Template = () => {
  const phoneNumber = "9823986199";
  const whatsappUrl = `https://wa.me/${phoneNumber}`;

  const handleContactClick = () => {
    window.location.href = "tel:9823986199";
  };

  const handleEmailClick = () => {
    window.location.href = "mailto:universalbioconpvt.ltd@gmail.com";
  };

  const webStyle = {
    container: {
      background: "green",
    },
    subContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
    },
    textContentColor:{
      color:"whitesmoke"
    },
    socialMediaIcon:{
      color: "white", cursor: "pointer"
    },
    socialMediaIcon2:{
      color: "white", cursor: "pointer", margin: "0 8px" 
    },
    socialMediaContainer:{
      display: "flex",
      alignItems: "center",
      justifyContent: "space-evenly",
    }


  };

  return (
    <>
      <div
        style={{ ...webStyle.container, position: "relative", zIndex: 0 }}
        className="waveBorder"
      >
        <div
          style={{
            ...webStyle.subContainer,
            zIndex: 2,
            position: "relative",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <div
              style={{
                borderLeft: "1px solid darkgreen",
                borderRight: "1px solid darkgreen",
                cursor: "pointer",
                padding: "5px",
              }}
              onClick={handleContactClick}
            >
              <CallIcon style={webStyle.textContentColor} />
              <span style={webStyle.textContentColor} className="HideContent">
                +91 9823986199
              </span>
            </div>
            <div
              onClick={handleEmailClick}
              style={{ cursor: "pointer", padding: "7px" }}
            >
              <EmailIcon style={webStyle.textContentColor}/>
              <span  style={webStyle.textContentColor} className="HideContent">
                universalbioconpvt.ltd@gmail.com
              </span>
            </div>
            <div
              style={{
                borderLeft: "1px solid darkgreen",
                borderRight: "1px solid darkgreen",
                cursor: "pointer",
                padding: "5px",
              }}
            >
              <a
                href={whatsappUrl}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
              >
                <WhatsAppIcon style={webStyle.textContentColor} />
                <span style={webStyle.textContentColor} className="HideContent">
                  +91 9823986199
                </span>
              </a>
            </div>
          </div>
          <div
            style={webStyle.socialMediaContainer}
          >
            <a
              href="https://www.facebook.com/universalbiocon?mibextid=LQQJ4d"
              target="_blank"
            >
              <FacebookIcon style={webStyle.socialMediaIcon} />
            </a>
            <a
              href="https://www.instagram.com/universal_biocon/?igsh=c3BtOTNpbGdpN2x4"
              target="_blank"
            >
              <InstagramIcon
                style={webStyle.socialMediaIcon2}
              />
            </a>
            <a
              href="https://www.youtube.com/@UniversalBio-ConPvt.Ltd."
              target="_blank"
            >
              <YouTubeIcon style={webStyle.socialMediaIcon} />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Template;
