import React, { useState } from 'react';
import ProductDetailsPage from './ProductDetailsPage.tsx';

interface Product {
  id: number;
  name: string;
  price: number;
  description?: string;
  imageUrl: string;
  chemicalType?: string;
  application?: string;
  physicalState?: string;
  releaseType?: string;
  storage?: string;
  packagingSize?:string;
  Classification?:string;
}

interface ProductListProps {
  products: Product[];
}

const ProductList: React.FC<ProductListProps> = ({ products }) => {
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleProductClick = (product: Product) => {
    setSelectedProduct(product);
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
    setSelectedProduct(null);
  };

  const webstyle = {
    Cards: {
      border: "1px solid #1b1b1b",
      margin: "10px",
      padding: "10px",
      cursor: "pointer",
      width: "280px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderTopRightRadius: "30%",
      borderBottomLeftRadius: "30%",
    },
  };
  return (
    <div style={{ display: "flex",flexWrap:"wrap" }} className='ItemCardsSection'>
      {products.map((product) => (
        <div
          key={product.id}
          style={{...webstyle.Cards,flexDirection: "column",}}
          onClick={() => handleProductClick(product)}
          className='ProductCard ProductListCardsBg '
        >
          <img
            src={product.imageUrl}
            alt={product.name}
            style={{ width: "300px", height: "300px" }}
          />
          <h2 style={{ fontSize: "16px",textAlign:"center",fontWeight:700,color:"black" }}>{product.name}</h2>
          {/* <p>{product.description}</p> */}
          <p style={{color:"black",fontWeight:700}}>{product.price} INR</p>
        </div>
      ))}
      <ProductDetailsPage
        open={dialogOpen}
        onClose={handleClose}
        product={selectedProduct}
      />
    </div>
  );
};

export default ProductList;
