import React from "react";
import { Carousel } from "react-bootstrap";
import {
  bannerVideo,
  cropsWallpaper,
  Banner1,
  Banner3,
  bannerTree,
} from "../Assets.tsx";
import { useTranslation } from "react-i18next";
import gsap from "gsap";
import { useGSAP } from "@gsap/react"
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);


const BannerCarousel = () => {
  useGSAP(() => {
    gsap.from(".gsapBannerImageCarousel h1", {
      opacity:0,
      duration:2,
      x:-500,
    })
  })
  useGSAP(() => {
    gsap.from(".gsapBannerImageCarousel p", {
      opacity:0,
      duration:2,
      x:500,
    })
  })
  const { t } = useTranslation();
  return (
    <Carousel fade={true} interval={3000}>
      <Carousel.Item style={{ height: "720px" }} className="BannerImageCarousel">
        <img className="d-block w-100" src={cropsWallpaper} alt="Third slide" />
        <Carousel.Caption
          style={{
            position: "absolute",
            bottom: "20px",
            left: 0,
            right: 0,
            textAlign: "center",
            color: "white",
          }}
          className="gsapBannerImageCarousel"
        >
          <h1>{t("navbar.h")}</h1>
          <p>{t("navbar.info")}</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item style={{ height: "720px" }}  className="BannerImageCarousel">
        <img className="d-block w-100" src={Banner3} alt="Third slide" />
        <Carousel.Caption
          style={{
            position: "absolute",
            bottom: "20px",
            left: 0,
            right: 0,
            textAlign: "center",
            color: "white",
          }}
          className="gsapBannerImageCarousel"
        >
          <h1>{t("navbar.h")}</h1>
          <p>
            {/* {t("navbar.info")} */}
            {t("navbar.info")}
          </p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item style={{ height: "720px" }}  className="BannerImageCarousel">
        <img className="d-block w-100" src={Banner1} alt="Third slide" />
        <Carousel.Caption
          style={{
            position: "absolute",
            bottom: "20px",
            left: 0,
            right: 0,
            textAlign: "center",
            color: "white",
          }}
        >
          <h1>{t("navbar.h")}</h1>
          <p>{t("navbar.info")}</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item style={{ height: "720px" }}  className="BannerImageCarousel">
        <video className="d-block w-100" autoPlay loop muted>
          <source src={bannerVideo} type="video/mp4" />
        </video>
        <Carousel.Caption
          style={{
            position: "absolute",
            bottom: "20px",
            left: 0,
            right: 0,
            textAlign: "center",
            color: "white",
          }}
        >
          <h1>{t("navbar.h")}</h1>
          <p>{t("navbar.info")}</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item style={{ height: "720px" }}  className="BannerImageCarousel">
        <img className="d-block w-100" src={bannerTree} alt="Third slide" />
        <Carousel.Caption
          style={{
            position: "absolute",
            bottom: "20px",
            left: 0,
            right: 0,
            textAlign: "center",
            color: "white",
          }}
        >
          <h1>{t("navbar.h")}</h1>
          <p>{t("navbar.info")}</p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};

export default BannerCarousel;
