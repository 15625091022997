import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import "./LangaugeSelector.css"

const LanguageSelector: React.FC = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    setIsOpen(false); 
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="language-selector gsapRightSideNav">
      <button style={{marginLeft: "40px"}} onClick={toggleDropdown} className="language-selector-button">
        {t("navbar.L")} &#9662;
      </button>
      {isOpen && (
        <div className="language-dropdown">
          <button onClick={() => changeLanguage('en')}>English</button>
          <button onClick={() => changeLanguage('hi')}>हिंदी</button>
          <button onClick={() => changeLanguage('mr')}>मराठी</button>
        </div>
      )}
    </div>
  );
};

export default LanguageSelector;
