import React, { useEffect, useState } from "react";
import Navbar1 from "./Navbar.tsx";
import Footer from "./Footer.tsx";
import "../Common-Components/slick.css";
import { awardsImg1, awardsImg2, awardsImg3, awardsImg4,  ourMission, ourPromise, ourVision, Award1, Award2, Award3, Award4, Award5} from "../Assets.tsx";
import BannerCarousel from "../Common-Components/BannerCarousel.tsx";
import ProductsCarousel from "../Common-Components/ProductsCarousel.tsx";
import AboutUs from "../Common-Components/AboutUs.tsx";
import { Box, Tab, Tabs } from "@mui/material";
import { t } from "i18next";
import HistoryCarousel from "../Common-Components/HistoryCarousel.tsx";
import gsap from "gsap";
import { useGSAP } from "@gsap/react"
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useTranslation } from "react-i18next";
import Team from "./Our Team/Team.tsx";
import WhatsAppButton from "../Common-Components/WhatsAppButton.tsx";
import Carousel2 from "./AwardCarousel/Carousel2.tsx";
gsap.registerPlugin(ScrollTrigger);

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        margin: "2% 0px",
      }}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const webStyle = {
  orangeBorder: {
    borderTopLeftRadius: "30%",
    borderBottomRightRadius: "30%",
    border: "8px solid orange",
    margin: "10px",
    width: "96.5%",
  },
  whiteBorder: {
    borderTopLeftRadius: "30%",
    borderBottomRightRadius: "30%",
    border: "7px solid white",
  },
  greenBorder: {
    borderTopLeftRadius: "30%",
    borderBottomRightRadius: "30%",
    border: "7px solid green",
  }
};

const HomePage = () => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useGSAP(() => {
    if (!isMobile) {
      gsap.from(".gsapOurMission", {
        opacity: 0,
        duration: 2,
        x: -500,
        scrollTrigger: {
          trigger: ".gsapOurMission",
          scroller: "body",
          markers: false,
          start: "top 50%"
        }
      })

    }
  })

  useGSAP(() => {
    if (!isMobile) {
      gsap.from(".gsapOurPromise", {
        opacity: 0,
        duration: 2,
        x: 500,
        scrollTrigger: {
          trigger: ".gsapOurPromise",
          scroller: "body",
          markers: false,
          start: "top 50%"
        }
      })

    }
  })

  useGSAP(() => {
    if (!isMobile) {
      gsap.from(".gsapOurVision", {
        opacity: 0,
        duration: 1,
        scale: 0.3,
        y: 20,
        delay: 1,
        scrollTrigger: {
          trigger: ".gsapOurVision",
          scroller: "body",
          markers: false,
          start: "top 50%"
        }
      })

    }
  })

  useGSAP(() => {
    if (!isMobile) {
      gsap.from(".gsapOurMissionImg", {
        opacity: 0,
        duration: 2,
        x: 500,
        scrollTrigger: {
          trigger: ".gsapOurMissionImg",
          scroller: "body",
          markers: false,
          start: "top 50%"
        }
      })

    }
  })


  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const ourHistory = [
    {
      image:awardsImg1,
        name: "2014",
      description: t("about.descriptions")
    },
    {
      image:
      awardsImg2,
        name: "2019",
      description: t("about.d1"),
    },

    {
      image:
      Award1,
        name: "2019",
      description: t("about.a1"),
    },

    {
      image:
      Award2,
        name: "2020",
      description: t("about.d1"),
    },

    {
      image:
      awardsImg3,
        name: "2021",
      description: t("about.d2"),
    },

    {
      image:
      Award4,
        name: "2021",
      description: t("about.a3"),
    },

    {
      image:
      Award3,
        name: "2022",
      description: t("about.a4"),
    },

    {
      image:
      Award5,
        name: "2023",
      description: t("about.a5"),
    },

    {
      image:
      awardsImg4,
        name: "2024",
      description: t("about.d3"),
    },
  ];


  return (
    <div>
      {/* <FlairAnimation/> */}
      <Navbar1 />
      <BannerCarousel />

      <ProductsCarousel />
      <AboutUs />
      <Box className="fixed-bg2">
        <Box
          sx={{
            width: "100%",
            padding: "3%",
            borderBottom: "5px solid black",
          }}
          className="fixed-bg-content2"
        >
          <Box sx={{ color: "white", border: "none", textDecoration: "none" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              centered
              sx={{
                "& .MuiTabs-indicator": {
                  display: "none",
                },
                "& .MuiTab-root": {
                  backgroundColor: "white",
                  color: "black",
                  border: "1px solid green",
                  margin: "0 1%",
                  width: "200px",
                  fontSize: "18px",
                },
                "& .Mui-selected": {
                  backgroundColor: "darkgreen",
                  color: "white !important",
                  border: "none !important",
                },
              }}
            >
              <Tab
                className="coolBeans gsapOurMission"
                label={t("about.mission")}
                {...a11yProps(0)}
              />
              <Tab
                className="coolBeans gsapOurVision"
                label={t("about.vision")}
                {...a11yProps(1)}
              />
              <Tab
                className="coolBeans gsapOurPromise"
                label={t("about.promise")}
                {...a11yProps(2)}
              />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "left",
                gap: "6%",
              }}
              className="our-mission"
            >
              <h5
                style={{ color: "black", width: "50%", textAlign: "justify" }}
                className="our-mission-section"
              >
                <p>{t("about.m")}</p>
                <ul>
                  <li>
                    <strong>{t("about.m1")}</strong> {t("about.m2")}
                  </li>
                  <li>
                    <strong>{t("about.m3")}</strong>
                    {t("about.m4")}
                  </li>
                  <li>
                    <strong>{t("about.m5")}</strong> {t("about.m6")}
                  </li>
                  <li>
                    <strong>{t("about.m7")}</strong> {t("about.m8")}
                  </li>
                </ul>
              </h5>
              <div>
                <div className="gsapOurMissionImg" style={webStyle.orangeBorder}>
                  <div style={webStyle.whiteBorder}>
                    <div style={webStyle.greenBorder}>
                      <img
                        src={ourMission}
                        alt="icon"
                        className="aboutUsImage"
                        style={{
                          borderTopLeftRadius: "30%",
                          borderBottomRightRadius: "30%",
                          height: "445px",
                          width: "470px",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "left",
                gap: "6%",
              }}
              className="our-mission"
            >
              <h5
                style={{ color: "black", width: "50%", textAlign: "justify" }}
                className="our-mission-section"
              >
                <p>{t("about.v")}</p>
                <ul>
                  <li>
                    <strong>{t("about.v1")}</strong> {t("about.v2")}
                  </li>
                  <li>
                    <strong>{t("about.v3")}</strong> {t("about.v4")}
                  </li>
                  <li>
                    <strong>{t("about.v5")}</strong> {t("about.v6")}
                  </li>
                  <li>
                    <strong>{t("about.v7")}</strong> {t("about.v8")}
                  </li>
                </ul>
              </h5>
              <div>
                <div style={webStyle.orangeBorder}>
                  <div style={webStyle.whiteBorder}>
                    <div style={webStyle.greenBorder}>
                      <img
                        src={ourVision}
                        alt="icon"
                        className="aboutUsImage"
                        style={{
                          borderTopLeftRadius: "30%",
                          borderBottomRightRadius: "30%",
                          height: "445px",
                          width: "470px",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "left",
                gap: "6%",
              }}
              className="our-mission"
            >
              <h5
                style={{ color: "black", width: "50%", textAlign: "justify" }}
                className="our-mission-section"
              >
                <p>{t("about.p")}</p>
                <ul>
                  <li>
                    <strong>{t("about.p1")}</strong> {t("about.p2")}
                  </li>
                  <li>
                    <strong>{t("about.p3")}</strong> {t("about.p4")}
                  </li>
                  <li>
                    <strong>{t("about.p5")}</strong> {t("about.p6")}
                  </li>
                  <li>
                    <strong>{t("about.p7")}</strong> {t("about.p8")}
                  </li>
                </ul>
              </h5>
              <div>
                <div style={webStyle.orangeBorder}>
                  <div style={webStyle.whiteBorder}>
                    <div style={webStyle.greenBorder}>
                      <img
                        src={ourPromise}
                        alt="icon"
                        className="aboutUsImage"
                        style={{
                          borderTopLeftRadius: "30%",
                          borderBottomRightRadius: "30%",
                          height: "445px",
                          width: "470px",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CustomTabPanel>
        </Box>
      </Box>
      <HistoryCarousel members={ourHistory} />
      <Team />
      <Carousel2 />
      <WhatsAppButton phoneNumber="+919823986199" />
      <Footer />
    </div>
  );
};

export default HomePage;
