import React, { useEffect, useState } from "react";
import "./GalleryPage.css";
import {
  Gallery1,
  Gallery10,
  Gallery11,
  Gallery2,
  Gallery3,
  Gallery4,
  Gallery5,
  Gallery6,
  Gallery7,
  Gallery8,
  Gallery9,
} from "../../Assets.tsx";
import { useTranslation } from "react-i18next";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const corporateImages = [
  Gallery1,
  Gallery2,
  Gallery3,
  Gallery4,
  Gallery5,
  Gallery6,
  Gallery7,
  Gallery8,
  Gallery9,
  Gallery10,
  Gallery11,
];

const GalleryPage = () => {
  const { t } = useTranslation();
  const [centerImgIndex, setCenterImgIndex] = useState(1);

  useGSAP(() => {
    gsap.from(".titleNameSection h1", {
      opacity: 0,
      duration: 1,
      scale: 0.5,
      y: 20,
      delay: 1,
    });
  });

  const handleCorporateThumbnailClick = (index) => {
    setCenterImgIndex(index);
  };

  const handleLeftImageClick = () => {
    setCenterImgIndex(getLeftImageIndex());
  };

  const handleRightImageClick = () => {
    setCenterImgIndex(getRightImageIndex());
  };

  const getLeftImageIndex = () => {
    return centerImgIndex === 0
      ? corporateImages.length - 1
      : centerImgIndex - 1;
  };

  const getRightImageIndex = () => {
    return centerImgIndex === corporateImages.length - 1
      ? 0
      : centerImgIndex + 1;
  };

  const getVisibleImages = () => {
    const startIndex = centerImgIndex - 2 < 0
      ? corporateImages.length + (centerImgIndex - 2)
      : centerImgIndex - 2;
    const endIndex = (centerImgIndex + 3) % corporateImages.length;

    if (startIndex < endIndex) {
      return corporateImages.slice(startIndex, endIndex + 1);
    } else {
      return [
        ...corporateImages.slice(startIndex, corporateImages.length),
        ...corporateImages.slice(0, endIndex + 1),
      ];
    }
  };
  useEffect(() => {
    const timer = setInterval(() => {
      setCenterImgIndex((prevIndex) => getRightImageIndex());
    }, 7000);

    return () => clearInterval(timer); 
  }, [centerImgIndex]);

  return (
    <div>
      <div className="titleNameSection">
        <h1 className="titleText">{t("aboutusPage.gallery")}</h1>
      </div>
      <div className="galleryCollageSectionDiv">
        <div className="galleryCollageSection">
          <img
            src={corporateImages[getLeftImageIndex()]}
            className="leftImage"
            alt="corporate-image-left"
            onClick={handleLeftImageClick}
          />
          <img
            src={corporateImages[centerImgIndex]}
            className="centerImage"
            alt="corporate-image-center"
          />
          <img
            src={corporateImages[getRightImageIndex()]}
            className="rightImage"
            alt="corporate-image-right"
            onClick={handleRightImageClick}
          />
        </div>
      </div>
      <div className="sliderContainer" style={{ marginBottom: "4%" }}>
        <button
          className="sliderArrow"
          onClick={handleCorporateThumbnailClick.bind(
            null,
            getLeftImageIndex()
          )}
        >
          &lt;
        </button>
        <div className="sliderSection">
          {getVisibleImages().map((img, index) => (
            <img
              key={index}
              src={img}
              className={`thumbnail ${
                index === 2 ? "active" : ""
              }`}
              onClick={() => handleCorporateThumbnailClick((centerImgIndex + index - 2 + corporateImages.length) % corporateImages.length)}
              alt="corporate-slider"
              style={{ background: "white", border: "2px solid lightgrey" }}
            />
          ))}
        </div>
        <button
          className="sliderArrow"
          onClick={handleCorporateThumbnailClick.bind(
            null,
            getRightImageIndex()
          )}
        >
          &gt;
        </button>
      </div>
    </div>
  );
};

export default GalleryPage;
