import React from "react";
import { useTranslation } from "react-i18next";
import { Logo, Footerbg, leaf1, leaf2, leaf3, leaf4 } from "../Assets.tsx";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import CallIcon from "@mui/icons-material/Call";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MailIcon from "@mui/icons-material/Mail";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { useHistory } from "react-router-dom";
import "./Footer.css";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Footer = () => {
  const phoneNumber = "9823986199";
  const whatsappUrl = `https://wa.me/${phoneNumber}`;
  useGSAP(() => {
    gsap.from(".gsapFooterSection", {
      opacity: 0,
      duration: 2,
      x: -500,
      scrollTrigger: {
        trigger: ".gsapFooterSection",
        scroller: "body",
        markers: false,
        start: "top 80%",
      },
    });
  });

  useGSAP(() => {
    gsap.from(".gsapFooterSection2", {
      opacity: 0,
      duration: 2,
      x: 500,
      scrollTrigger: {
        trigger: ".gsapFooterSection2",
        scroller: "body",
        markers: false,
        start: "top 65%",
      },
    });
  });

  useGSAP(() => {
    gsap.from(".gsapFooterLogo", {
      opacity: 0,
      duration: 1,
      scale: 0.5,
      y: 20,
      delay: 1,
      scrollTrigger: {
        trigger: ".gsapFooterLogo",
        scroller: "body",
        markers: false,
        start: "top 65%",
      },
    });
  });

  const { t } = useTranslation();
  const history = useHistory();

  const handleLinkClick = (path: string) => {
    history.push(path);
  };

  const handleContactClick = () => {
    window.location.href = "tel:9823986199";
  };

  const handleEmailClick = () => {
    window.location.href = "universalbioconpvt.ltd@gmail.com";
  };

  return (
    <div
      className="footer"
      style={{
        height: "700px",
        width: "100%",
        background: `url(${Footerbg}) no-repeat center center/cover`,
        color: "black",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "0px 20px",
        position: "relative",
        top: "24px",
        overflow: "hidden",
      }}
    >
      <img
        src={Logo}
        alt="icon"
        className="footer-logo gsapFooterLogo"
        onClick={() => history.push("/")}
      />
      <span className="footer-title gsapFooterSection2">
        {t("footer.title")}
      </span>
      <div className="FooterContainer gsapFooterSection">
        <div className="FooterSection">
          <span className="footer-heading">{t("footer.customerService")}</span>
          <span className="footer-link" onClick={handleContactClick}>
            <CallIcon className="social-icon" />
            {t("Call Us")}
          </span>
          <span className="footer-link" onClick={handleContactClick}>
            +91 9823986199
          </span>
          <a
            href={whatsappUrl}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
          >
            <span className="footer-link">
              <WhatsAppIcon className="social-icon" />
              {t("WhatsApp Us")}
            </span>
            <span className="footer-link" style={{ display: "flex" }}>
              +91 9823986199
            </span>
          </a>
        </div>
        <div className="FooterSection">
          <span className="footer-heading">{t("footer.quickLinks")}</span>
          <a
            href="/"
            onClick={() => handleLinkClick("/")}
            className="footer-link"
          >
            {t("footer.home")}
          </a>
          <a
            onClick={() => handleLinkClick("/products")}
            className="footer-link"
          >
            {t("footer.products")}
          </a>
          <a
            onClick={() => handleLinkClick("/About-us")}
            className="footer-link"
          >
            {t("footer.aboutUs")}
          </a>
          <a
            onClick={() => handleLinkClick("/awards")}
            className="footer-link"
          >
            {t("footer.gallery")}
          </a>
        </div>
        <div className="FooterSection">
          <span className="footer-heading">{t("footer.followUs")}</span>
          <div>
            <FacebookIcon
              className="social-icon"
              onClick={() =>
                window.open("https://www.facebook.com/universalbiocon?mibextid=LQQJ4d", "_blank")
              }
            />
            <InstagramIcon
              className="social-icon"
              onClick={() =>
                window.open("https://www.instagram.com/universal_biocon?igsh=c3BtOTNpbGdpN2x4", "_blank")
              }
            />
            <YouTubeIcon
              className="social-icon"
              onClick={() =>
                window.open("https://youtube.com/@UniversalBio-ConPvt.Ltd.?si=TW3r7Q1elukSjzA8", "_blank")
              }
            />
            <AddShoppingCartIcon
              className="social-icon"
              onClick={() => (
                window.open("https://kisanshopee.com/", "_blank"),
                "noopener,noreferrer"
              )}
            />
          </div>
        </div>
        <div className="FooterSectionEmail">
          <span className="footer-heading">
            <MailIcon className="social-icon" />
            {t("footer.mail")}
          </span>
          <span className="footer-email" onClick={handleEmailClick}>
            universalbioconpvt.ltd@gmail.com
          </span>
        </div>
        <div className="FooterSection">
          <span className="footer-heading">{t("footer.keyPersonnel")}</span>
          <span className="footer-link">{t("footer.name")}</span>
          <span className="footer-link">(Director)</span>
        </div>
      </div>
      <div className="wheat-icons">
        <img src={leaf1} alt="leaf1" className="wheat-icon wheat-icon1" />
        <img src={leaf2} alt="leaf2" className="wheat-icon wheat-icon2" />
        <img src={leaf3} alt="leaf3" className="wheat-icon wheat-icon3" />
        <img src={leaf4} alt="leaf4" className="wheat-icon wheat-icon4" />
      </div>
      <h6
        style={{
          textAlign: "center",
          position: "relative",
          top: "150px",
          color: "gray",
          fontWeight: 200,
          fontSize: "12px",
        }}
      >
        2024 © Universal Biocon Pvt. Ltd. All rights reserved.
      </h6>
    </div>
  );
};

export default Footer;
