import React from "react";
import Navbar from "../Navbar.tsx";
import Footer from "../Footer.tsx";
import AwardsAndAchievementList from "./AwardsAndAchievementList.tsx";
import {
  awardsBannerImg,
  awardsImg1,
  awardsImg2,
  awardsImg3,
  awardsImg4,
  awardsImg5,
  awardsImg6,
  awardsImg7,
  awardsImg8,
  awardsImg9,
} from "../../Assets.tsx";
import "./AwardsAndAchievement.css";
import WhatsAppButton from "../../Common-Components/WhatsAppButton.tsx";

const AwardsAndAchievement = () => {
  const products = [
    {
      id: 1,
      name: "Business Excellence Award",
      price: 990,
      description: "From Sakal Pune",
      imageUrl: awardsImg1,
    },
    {
      id: 2,
      name: "With Padmashri Popatrao Pawar And Padmashri Dharaumata",
      price: 65,
      description: "Discussing on farmers improvement",
      imageUrl: awardsImg2,
    },
    {
      id: 3,
      name: "Discussing on Pomegranate Production Achievement",
      price: 65,
      description:
        "With Mr. Devlankar and Dr. B.T.Gore discussing on Pomegranate production",
      imageUrl: awardsImg3,
    },
    {
      id: 4,
      name: "IBN Lockmat Live Achievement",
      price: 65,
      description:
        "On Lokmat News channel explaining importance of Organic farming",
      imageUrl: awardsImg5,
    },
    {
      id: 5,
      name: "awards ceremony",
      price: 65,
      description:
        "Participant in Mahatma Phule Krishi Vidyapeeth Farm awards ceremony",
      imageUrl: awardsImg4,
    },
    {
      id: 6,
      name: "IBN Lokmat event",
      price: 65,
      description:
        "Shared Stage in IBN Lokmat event with Honorable Guest Dr. Neelam Gorhe and other respected Chief Guest.",
      imageUrl: awardsBannerImg,
    },
    {
      id: 7,
      name: "Industry innovative award dubai 2019",
      price: 65,
      description: "",
      imageUrl: awardsImg6,
    },
    {
      id: 8,
      name: "india 5000 best m.s.m.e award",
      price: 65,
      description: "",
      imageUrl: awardsImg7,
    },
    {
      id: 9,
      name: "doctorate degree and Nelson Mandela nobel peace award-2021",
      price: 65,
      description: "",
      imageUrl: awardsImg8,
    },
    {
      id: 10,
      name: "Articles in Forbes, agrovan, outlook business, fortune india and fashion vista",
      price: 65,
      description: "",
      imageUrl: awardsImg9,
    },
  ];

  return (
    <div>
      <Navbar />
      <img
        src={awardsBannerImg}
        alt="icon"
        style={{ width: "100%", height: "685px" }}
        className="aboutUsImage"
      />
      <div
        style={{
          width: "100%",
          height: "auto",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div style={{ width: "100%", height: "auto" }}>
          <AwardsAndAchievementList products={products} />
        </div>
      </div>
      <WhatsAppButton phoneNumber="+919823986199" />
      <Footer />
    </div>
  );
};

export default AwardsAndAchievement;
