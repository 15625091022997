import React, { useState, useEffect } from "react";
import {
  product100,
  product101,
  product102,
  product103,
  product104,
  product105,
  product106,
  product107,
  product108,
  product109,
  product110,
  product111,
  product112,
  product113,
  product114,
  product115,
  product116,
  product117,
  Certificate1,
  Certificate2,
  Certificate3,
  // Certificate4,
  Certificate5,
  Certificate6,
  // Certificate7,
  Certificate
} from "../../Assets.tsx";
import "./Carousel.css";
import { useTranslation } from "react-i18next";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

interface Item {
  image: string;
  name: string;
}

const insects: Item[] = [
  { image: Certificate1, name: "Wipro" },
  { image: Certificate2, name: "kaka halwai" },
  { image: Certificate3, name: "Rio Energy Drinks" },
  // { image: Certificate4, name: "Axis Bank" },
  { image: Certificate5, name: "Axis Bank" },
  { image: Certificate6, name: "Axis Bank" },
  // { image: Certificate7, name: "Axis Bank" },
  { image: Certificate, name: "Axis Bank" },
  // { image: product104, name: "Atum Informartion Technologies" },
  // { image: product105, name: "Sarwarta technologies" },
  // { image: product106, name: "sakhar sankul mumbai" },
  // { image: product107, name: "sakas milk patan" },
  // { image: product108, name: "Regus business centre" },
  // { image: product109, name: "JP Naik Centre for education and development" },
  // { image: product110, name: "Hotel Shangrila" },
  // { image: product111, name: "COEPEnviro baner" },
  // { image: product112, name: "Cc & Company" },
  // {
  //   image: product113,
  //   name: "Aditya Packaging & Consulting Services Pvt. Ltd",
  // },
  // { image: product114, name: "Hotel Barometer" },
  // { image: product115, name: "Forest Escapes Koyna Resort" },
  // { image: product116, name: "Hotel Mahabaleshwar" },
  // { image: product117, name: "Axiverse Management Solutions Llp" },
];

const Carousel2: React.FC = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentSlide2, setCurrentSlide2] = useState(0);
  const [items, setItems] = useState<Item[]>(insects);
  const [items2, setItems2] = useState<Item[]>(insects);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const { t } = useTranslation();


  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useGSAP(() => {
    if (!isMobile) {
      gsap.from(".GsapClientssection", {
        opacity: 0,
        duration: 2,
        x: 500,
        scrollTrigger: {
          trigger: ".GsapClientssection ",
          scroller: "body",
          markers: false,
          start: "top 55%",
        },
      });
    }
  });
  useGSAP(() => {
    if (!isMobile) {
      gsap.from(".GsapCarousel", {
        opacity: 0,
        duration: 2,
        x: -500,
        scrollTrigger: {
          trigger: ".GsapCarousel",
          scroller: "body",
          markers: false,
          start: "top 50%",
        },
      });
    }
  });
  useEffect(() => {
    const timer = setInterval(() => {
      nextSlide();
    }, 7000);
    return () => clearInterval(timer);
  }, [currentSlide]);

  useEffect(() => {
    const timer = setInterval(() => {
      nextSlide2();
    }, 5000);
    return () => clearInterval(timer);
  }, [currentSlide2]);
  const itemsPerSlide = 3;
  const totalSlides = Math.ceil(items.length / itemsPerSlide);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % totalSlides);
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + totalSlides) % totalSlides);
  };

  const nextSlide2 = () => {
    setCurrentSlide2((prevSlide) => (prevSlide + 1) % totalSlides2);
  };

  const prevSlide2 = () => {
    setCurrentSlide2(
      (prevSlide) => (prevSlide - 1 + totalSlides2) % totalSlides2
    );
  };

  const getSlideItems = () => {
    const startIndex = currentSlide * itemsPerSlide;
    return items.slice(startIndex, startIndex + itemsPerSlide);
  };
  const itemsPerSlide2 = 1;
  const totalSlides2 = Math.ceil(items2.length / itemsPerSlide2);
  const getSlideItems2 = () => {
    const startIndex2 = currentSlide2 * itemsPerSlide2;
    return items2.slice(startIndex2, startIndex2 + itemsPerSlide2);
  };

  return (
    <div className="carousel-container25 fixed-bg-CertificatePage">
      <h1
        style={{
          textAlign: "center",
          color: "black",
          textTransform: "capitalize",
          fontSize: "40px",
        }}
        className="GsapClientssection"
      >
        {t("ourTeam.certificate")}
      </h1>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        className="fixed-bg-content-CertificatePage"
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
          }}
          className="arrowHover25"
        >
          <div
            style={{
              cursor: "pointer",
              fontSize: "48px",
              color: "black",
              marginBottom: "5px",
            }}
            className="scroll-arrow25 desktopView"
            id="scroll-left"
            onClick={prevSlide}
          >
            &#8592;
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
          }}
          className="arrowHover25"
        >
          <div
            style={{
              cursor: "pointer",
              fontSize: "48px",
              color: "black",
              marginBottom: "5px",
            }}
            className="scroll-arrow25 MobileView"
            id="scroll-left"
            onClick={prevSlide2}
          >
            &#8592;
          </div>
        </div>

        <div className="carousel25 items">
          {getSlideItems().map((item, index) => (
            <div key={index} className="carousel-item25">
              <img
                src={item.image}
                alt={item.name}
                className="carousel-image25"
              />
              {/* <h2 style={{ color: "#0092cb", textTransform: "capitalize" }}>{item.name}</h2> */}
            </div>
          ))}
        </div>
        <div className="carousel25 MobileView">
          {getSlideItems2().map((item, index) => (
            <div key={index} className="carousel-item25">
              <img
                src={item.image}
                alt={item.name}
                className="carousel-image25"
              />
              {/* <h2 style={{ color: "#0092cb", textTransform: "capitalize" }}>{item.name}</h2> */}
            </div>
          ))}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
          }}
          className="arrowHover25"
        >
          <div
            style={{
              cursor: "pointer",
              fontSize: "48px",
              color: "black",
              marginBottom: "5px",
            }}
            className="scroll-arrow25 MobileView"
            id="scroll-right"
            onClick={nextSlide2}
          >
            &#8594;
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
          }}
          className="arrowHover25"
        >
          <div
            style={{
              cursor: "pointer",
              fontSize: "48px",
              color: "black",
              marginBottom: "5px",
            }}
            className="scroll-arrow25 desktopView"
            id="scroll-right"
            onClick={nextSlide}
          >
            &#8594;
          </div>
        </div>
      </div>
    </div>
  );
};

export default Carousel2;
