import React from "react";
import { Carousel } from "react-bootstrap";
import {
  bannerVideo,
  cropsWallpaper,
  Banner1,
  Banner3,
  bannerTree,
  ProductBanner1,
  ProductBanner2,
  ProductBanner3,
} from "../Assets.tsx";
import { useTranslation } from "react-i18next";
import gsap from "gsap";
import { useGSAP } from "@gsap/react"
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);


const AboutUsBannerCarousel = () => {
  useGSAP(() => {
    gsap.from(".gsapBannerImageCarousel h1", {
      opacity:0,
      duration:2,
      x:-500,
    })
  })
  useGSAP(() => {
    gsap.from(".gsapBannerImageCarousel p", {
      opacity:0,
      duration:2,
      x:500,
    })
  })
  const { t } = useTranslation();
  return (
    <Carousel fade={true} interval={3000}>
      <Carousel.Item style={{ height: "720px",width:"100%" }}  className="BannerImageCarousel">
        <img style={{width: "100%", height: "702px"}} className="d-block w-100" src={ProductBanner2} alt="Third slide" />
        <Carousel.Caption
          style={{
            position: "absolute",
            bottom: "20px",
            left: 0,
            right: 0,
            textAlign: "center",
            color: "white",
          }}
          className="gsapBannerImageCarousel"
        >
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item style={{ height: "720px" }}  className="BannerImageCarousel">
        <img className="d-block" style={{width:"100%", height: "723px", paddingTop: "28px"}} src={ProductBanner1} alt="Third slide" />
      </Carousel.Item>
      <Carousel.Item style={{ height: "720px" }}  className="BannerImageCarousel">
        <img style={{width: "100%", height: "702px", paddingTop: "49px"}} className="d-block w-100" src={ProductBanner3} alt="Third slide" />
      </Carousel.Item>
    </Carousel>
  );
};

export default AboutUsBannerCarousel;
