import React, { useState, useEffect } from "react";
import {
  Product1,
  Product2,
  Product3,
  Product4,
  Product5,
  Product6,
  Product7,
  Product8
} from "../Assets.tsx";
import "./ProductsCarousel.css";
import { useTranslation } from "react-i18next";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { useHistory } from "react-router-dom";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

interface Item {
  image: string;
  name: string;
}

const products: Item[] = [
  {
    image: Product1,
    name: "Bio-Trust",
  },
  { image: Product2, name: "Florin" },
  { image: Product3, name: "Biosoul-Mix" },
  { image: Product4, name: "Germinate-21" },
  {
    image: Product5,
    name: "All-Source-9-Bio-Kit",
  },
  {
    image: Product6,
    name: "Supremo",
  },
  { image: Product7, name: "Bactricell" },
  {
    image: Product8,
    name: "Trikaal",
  },
];

const Carousel2: React.FC = () => {
  const { t } = useTranslation();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentSlide2, setCurrentSlide2] = useState(0);
  const [items] = useState<Item[]>(products);
  const [items2] = useState<Item[]>(products);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useGSAP(() => {
    gsap.from(".backgroundImageOurProduct h1", {
      opacity: 0,
      duration: 2,
      x: -500,
      scrollTrigger: {
        trigger: ".backgroundImageOurProduct h1",
        scroller: "body",
        markers: false,
        start: "top 50%",
      },
    });
  });
  useGSAP(() => {
    if (!isMobile) {
      gsap.from(".backgroundImageOurProduct h5", {
        opacity: 0,
        duration: 2,
        x: 500,
        scrollTrigger: {
          trigger: ".backgroundImageOurProduct h5",
          scroller: "body",
          markers: false,
          start: "top 50%",
        },
      });
    }
  });

  useEffect(() => {
    const timer = setInterval(() => {
      nextSlide();
    }, 7000);
    return () => clearInterval(timer);
  }, [currentSlide]);

  useEffect(() => {
    const timer = setInterval(() => {
      nextSlide2();
    }, 5000);
    return () => clearInterval(timer);
  }, [currentSlide2]);
  const itemsPerSlide = 4;
  const totalSlides = Math.ceil(items.length / itemsPerSlide);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % totalSlides);
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + totalSlides) % totalSlides);
  };

  const nextSlide2 = () => {
    setCurrentSlide2((prevSlide) => (prevSlide + 1) % totalSlides2);
  };

  const prevSlide2 = () => {
    setCurrentSlide2(
      (prevSlide) => (prevSlide - 1 + totalSlides2) % totalSlides2
    );
  };

  const getSlideItems = () => {
    const startIndex = currentSlide * itemsPerSlide;
    return items.slice(startIndex, startIndex + itemsPerSlide);
  };
  const itemsPerSlide2 = 1;
  const totalSlides2 = Math.ceil(items2.length / itemsPerSlide2);
  const getSlideItems2 = () => {
    const startIndex2 = currentSlide2 * itemsPerSlide2;
    return items2.slice(startIndex2, startIndex2 + itemsPerSlide2);
  };

  const history = useHistory();

  const handleProductclick = () => {
    history.push("/products");
  };

  return (
    <div className="backgroundImageOurProduct">
      <h1
        style={{
          textAlign: "center",
          textTransform: "capitalize",
          marginTop: "100px",
          fontSize: "45px",
          fontWeight: 700,
          color: "#00A550",
        }}
        className="TopProductsTitle"
      >
        {/* {t("navbar.name")} */}
        <span style={{ color: "#BED630", backgroundColor: "white" }}>
          {t("navbar.name")}
        </span>{" "}
        <span style={{ color: "#00A550", backgroundColor: "white" }}>
          {t("navbar.nameH")}
        </span>
      </h1>
      <h5
        style={{
          textAlign: "center",
          width: "50%",
          backgroundColor: "transparent",
        }}
        className="TopProductssubTitle"
      >
        {t("navbar.about")}
        <div
          className="right-arrow-section"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span
            onClick={handleProductclick}
            style={{ color: "orange", cursor: "pointer" }}
          >
            &nbsp;{t("navbar.more")}&nbsp;
          </span>
          <div
            onClick={handleProductclick}
            className="scroll-arrow"
            style={{
              cursor: "pointer",
              fontSize: "35px",
              color: "green",
              marginBottom: "5px",
              animation: "move 0.5s infinite alternate",
            }}
            id="scroll-right"
          >
            &#8594;
          </div>
        </div>
      </h5>

      <div id="products" className="carousel-container5 our-product-container">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
          className="desktop-view"
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "50%",
            }}
            className="arrowHover"
          >
            <div
              style={{
                cursor: "pointer",
                fontSize: "48px",
                color: "black",
                marginBottom: "5px",
              }}
              className="scroll-arrow"
              id="scroll-left"
              onClick={prevSlide}
            >
              &#8592;
            </div>
          </div>

          <div className="carousel5">
            {getSlideItems().map((item, index) => (
              <div className="card" key={index}>
                <div className="wrapper">
                  <img
                    src={item.image}
                    alt={item.name}
                    className="cover-image"
                  />
                </div>
                <img src={item.image} alt={item.name} className="character" />
                <div className="product-name">{item.name}</div>
              </div>
            ))}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "50%",
            }}
            className="arrowHover"
          >
            <div
              style={{
                cursor: "pointer",
                fontSize: "48px",
                color: "black",
                marginBottom: "5px",
              }}
              className="scroll-arrow"
              id="scroll-right"
              onClick={nextSlide}
            >
              &#8594;
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          className="mobile-view"
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "50%",
            }}
            className="arrowHover"
          >
            <div
              style={{
                cursor: "pointer",
                fontSize: "48px",
                color: "black",
                marginBottom: "5px",
              }}
              className="scroll-arrow"
              id="scroll-left"
              onClick={prevSlide2}
            >
              &#8592;
            </div>
          </div>

          <div className="carousel5">
            {getSlideItems2().map((item, index) => (
              <>
                <div className="card">
                  <div className="wrapper">
                    <img
                      src={item.image}
                      alt={item.name}
                      className="cover-image"
                    />
                  </div>
                  <img src={item.image} alt={item.name} className="character" />
                  <div className="product-name">{item.name}</div>
                </div>
              </>
            ))}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "50%",
            }}
            className="arrowHover"
          >
            <div
              style={{
                cursor: "pointer",
                fontSize: "48px",
                color: "black",
                marginBottom: "5px",
              }}
              className="scroll-arrow"
              id="scroll-right"
              onClick={nextSlide2}
            >
              &#8594;
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Carousel2;
