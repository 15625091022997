import React, { useState, useEffect } from "react";
import { Dialog, DialogContent, DialogActions } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface Product {
  id: number;
  name: string;
  price: number;
  description?: string;
  imageUrl: string;
  chemicalType?: string;
  application?: string;
  physicalState?: string;
  releaseType?: string;
  storage?: string;
  packagingSize?: string;
  Classification?: string;
  additionalImages?: string[];
  productUrl?: string;
}

interface ProductDetailsDialogProps {
  open: boolean;
  onClose: () => void;
  product: Product | null;
}

function ImageMagnifier({
  src,
  width,
  height,
  magnifierHeight = 170,
  magnifieWidth = 170,
  zoomLevel = 2,
}) {
  const [[x, y], setXY] = useState([0, 0]);
  const [[imgWidth, imgHeight], setSize] = useState([0, 0]);
  const [showMagnifier, setShowMagnifier] = useState(false);

  return (
    <div
      style={{
        position: "relative",
        height: height,
        width: width,
      }}
    >
      <img
        className="DetailsImage"
        src={src}
        style={{
          width: "500px",
          height: "500px",
          aspectRatio: "4/5",
          border:"1px solid lightgrey",
          marginLeft:"5px"
        }}
        onMouseEnter={(e) => {
          const elem = e.currentTarget;
          const { width, height } = elem.getBoundingClientRect();
          setSize([width, height]);
          setShowMagnifier(true);
        }}
        onMouseMove={(e) => {
          const elem = e.currentTarget;
          const { top, left } = elem.getBoundingClientRect();

          const x = e.pageX - left - window.pageXOffset;
          const y = e.pageY - top - window.pageYOffset;
          setXY([x, y]);
        }}
        onMouseLeave={() => {
          setShowMagnifier(false);
        }}
        alt={"img"}
      />

      <div
        style={{
          display: showMagnifier ? "" : "none",
          position: "absolute",
          pointerEvents: "none",
          height: `${magnifierHeight}px`,
          width: `${magnifieWidth}px`,
          top: `${y - magnifierHeight / 2}px`,
          left: `${x - magnifieWidth / 2}px`,
          opacity: "1",
          border: "1px solid lightgray",
          backgroundColor: "white",
          backgroundImage: `url('${src}')`,
          backgroundRepeat: "no-repeat",
          backgroundSize: `${imgWidth * zoomLevel}px ${
            imgHeight * zoomLevel
          }px`,
          backgroundPositionX: `${-x * zoomLevel + magnifieWidth / 2}px`,
          backgroundPositionY: `${-y * zoomLevel + magnifierHeight / 2}px`,
        }}
      ></div>
    </div>
  );
}

const ProductDetailsPage: React.FC<ProductDetailsDialogProps> = ({
  open,
  onClose,
  product,
}) => {
  const [currentImage, setCurrentImage] = useState<string>(
    product?.imageUrl || ""
  );

  useEffect(() => {
    if (product && open) {
      setCurrentImage(product.imageUrl);
    }
  }, [product, open]);

  if (!product) return null;

  const handleImageClick = (imgUrl: string) => {
    setCurrentImage(imgUrl);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl">
      <DialogActions>
        <CloseIcon onClick={onClose} style={{ cursor: "pointer" }} />
      </DialogActions>
      <div className="fixed-bg-DetailsPage">
        <DialogContent
          style={{ display: "flex" }}
          className="DetailsDialogBox fixed-bg-content-DetailsPage"
        >
          <div
            style={{ display: "flex", alignItems: "center" }}
            className="ProductListImageContainer"
          >
            {product.additionalImages &&
              product.additionalImages.length > 0 && (
                <div
                  className="side-images-container ProductlistImage"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  {product.additionalImages.map((imgUrl, index) => (
                    <img
                      key={index}
                      src={imgUrl}
                      alt={`Product side image ${index + 1}`}
                      style={{
                        width: "100px",
                        height: "100px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleImageClick(imgUrl)}
                    />
                  ))}
                </div>
              )}
            <ImageMagnifier
              src={currentImage}
              width={undefined}
              height={undefined}
            />
          </div>
          <div
            style={{ marginLeft: "20px", width: "40%" }}
            className="ProductListDescription"
          >
            <h2 style={{ color: "#6ca614" }}>{product.name}</h2>
            <div style={{ color: "black" }}>
              <p>
                <strong>Price:</strong> {product.price} INR
              </p>
              {product.chemicalType && (
                <p>
                  <strong>Chemical Name:</strong> {product.chemicalType}
                </p>
              )}
              {product.Classification && (
                <p>
                  <strong>Classification:</strong> {product.Classification}
                </p>
              )}
              {product.application && (
                <p>
                  <strong>Application:</strong> {product.application}
                </p>
              )}
              {product.physicalState && (
                <p>
                  <strong>Physical State:</strong> {product.physicalState}
                </p>
              )}
              {product.releaseType && (
                <p>
                  <strong>Release Type:</strong> {product.releaseType}
                </p>
              )}
              {product.storage && (
                <p>
                  <strong>Storage:</strong> {product.storage}
                </p>
              )}
              {product.packagingSize && (
                <p>
                  <strong>Packaging Size:</strong> {product.packagingSize}
                </p>
              )}
              {product.description && (
                <p style={{ width: "100%", textAlign: "justify" }}>
                  <strong>Description:</strong> {product.description}
                </p>
              )}
              {product.productUrl && (
                <a href={product.productUrl} target="_blank">
                  <button className="btn">Shop Now</button>
                </a>
              )}
            </div>
          </div>
        </DialogContent>
      </div>
    </Dialog>
  );
};

export default ProductDetailsPage;
