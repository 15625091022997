import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import './WhatsAppButton.css'; 

interface WhatsAppButtonProps {
  phoneNumber: string;
}

const WhatsAppButton: React.FC<WhatsAppButtonProps> = ({ phoneNumber }) => {
  const handleWhatsAppClick = () => {
    const url = `https://wa.me/${phoneNumber}`;
    window.open(url, '_blank');
  };

  return (
    <div 
    onClick={handleWhatsAppClick} 
    className="whatsapp-button" 
    style={{
      position: 'fixed',
      bottom: '20px',
      right: '20px',
      cursor: 'pointer',
      backgroundColor: 'white',
      borderRadius: '50%',
      width: '50px',
      height: '50px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: '1111111'
    }}
  >
      <FontAwesomeIcon icon={faWhatsapp} size="3x" color="Green"/>
    </div>
  );
};

export default WhatsAppButton;
