import React from "react";
import "./AwardsAndAchievement.css";

interface Product {
  id: number;
  name: string;
  price: number;
  description: string;
  imageUrl: string;
}

interface AwardsAndAchievementListProps {
  products: Product[];
}

const HandleWhatsApp = () => {
  const phoneNumber = "+919823986199";
  const url = `https://wa.me/${phoneNumber}`;
  window.open(url, "_blank");
};

const AwardsAndAchievementList: React.FC<AwardsAndAchievementListProps> = ({
  products,
}) => {
  return (
    <div>
      <div className="container">
        <h1>Awards And Achievements</h1>
        <div className="card-row">
          {products.map((product) => (
            <div key={product.id} className="service">
              <img
                src={product.imageUrl}
                alt={product.name}
                style={{
                  width: "310px",
                  height: "250px",
                  borderRadius: "10px",
                  marginTop: "14px",
                }}
                className="smallImages"
              />
              <h2 className="service_heading">{product.name}</h2>
              <p className="service_para">{product.description}</p>
              <a onClick={HandleWhatsApp} className="service_btn">
                Contact Us
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AwardsAndAchievementList;
