import React from "react";
import Navbar from "../Navbar.tsx";
import Footer from "../Footer.tsx";
import "./About.css";
import {
  AboutUsBanner,
  agro,
  awardsImg1,
  awardsImg2,
  awardsImg3,
  awardsImg4,
  Banner2,
  History,
  missionagro,
  ourPromise,
  visionagro,
} from "../../Assets.tsx";
import HistoryCarousel from "../../Common-Components/HistoryCarousel.tsx";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ReactTyped } from "react-typed";
import WhatsAppButton from "../../Common-Components/WhatsAppButton.tsx";
import GalleryPage from "../Gallery-Section/GalleryPage.tsx";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    overflowX: "hidden",
    borderTop: "5px solid black",
    borderBottom: "5px solid black",
  },
  aboutUsImage: {
    right: 0,
    top: 0,
  },
  heading: {
    color: "black",
    fontSize: "40pt",
    textAlign: "left",
    marginBottom: "4%",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  historyHeading: {
    color: "white",
    textAlign: "justify",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  text: {
    color: "Black",
    fontSize: "14pt",
    textAlign: "justify",
    marginBottom: "3%",
  },
  historyText: {
    color: "black",
    fontSize: "14pt",
    textAlign: "justify",
  },
  button: {
    backgroundColor: "#1C82C4",
    color: "white",
    width: "185px",
    textAlign: "right",
    padding: "10px",
    fontSize: "14pt",
    boxShadow: "rgb(38, 57, 77) 0px 20px 30px -10px",
    transition: "all 0.5s ease-in-out",
    "&:hover": {
      backgroundColor: "green",
      color: "white",
      boxShadow: "rgba(0, 0, 0, 0.56) 0px 22px 70px 4px",
      transform: "translateY(10px)",
    },
  },
  historyButton: {
    backgroundColor: "#1C82C4",
    color: "white",
  },
}));

const AboutUsPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const webStyle = {
    container: {
      height: "auto",
      width: "100%",
    },
    bannerContainer: {
      width: "100%",
      height: "auto",
    },
    bannerImage: {
      width: "100%",
      height: "auto",
    },
    bannerText: {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      color: "white",
      fontSize: "2rem",
    },
    historySection: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-evenly",
      margin: "20px 50px",
    },
    ourVisionSection: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-evenly",

      margin: "20px 50px",
    },
    greenBorder: {
      borderTopLeftRadius: "30%",
      borderBottomRightRadius: "30%",
      border: "13px solid green",
      margin: "10px",
    },
    whiteBorder: {
      borderTopLeftRadius: "30%",
      borderBottomRightRadius: "30%",
      border: "10px solid white",
    },
    orangeBorder: {
      borderTopLeftRadius: "30%",
      borderBottomRightRadius: "30%",
      border: "8px solid orange",
      margin: "10px",
      width: "96.5%",
    },
    // whiteBorder: {
    //   borderTopLeftRadius: "30%",
    //   borderBottomRightRadius: "30%",
    //   border: "7px solid white",
    // },
    // greenBorder: {
    //   borderTopLeftRadius: "30%",
    //   borderBottomRightRadius: "30%",
    //   border: "7px solid green",
    // },
  };

  const ourHistory = [
    {
      image:awardsImg1,
        name: "2014",
      description: t("about.descriptions")
    },
    {
      image:
      awardsImg2,
        name: "2019",
      description: t("about.d1"),
    },
    {
      image:
      awardsImg3,
        name: "2021",
      description: t("about.d2"),
    },
    {
      image:
      awardsImg4,
        name: "2024",
      description: t("about.d3"),
    },
  ];

  return (
    <div className="backgroungimg">
      <div style={webStyle.container}>
        <Navbar />
        <div style={{ ...webStyle.bannerContainer, position: "relative" }}>
          <img
            src={AboutUsBanner}
            alt="About Us Banner"
            style={webStyle.bannerImage}
          />
          <div
            style={{
              ...webStyle.bannerText,
              position: "absolute",
              textAlign: "center",
              textTransform: "uppercase",
            }}
            className="AboutusTitle"
          >
            {t("aboutusPage.BannerTitle")}
          </div>
        </div>
        <div
          style={{ ...webStyle.historySection, flexDirection: "row-reverse" }}
          className="aboutUsContainer2"
          id="About-Company-History"
        >
          <div
            style={{ width: "50%", marginRight: "10px" }}
            className="AboutUsTextContainer"
          >
            <h1 style={{ color: "green", fontSize: "40px", fontWeight: 600 }}>
              <span style={{ color: "#c0e61d" }}>
                {t("aboutusPage.heading")}
              </span>{" "}
              {t("aboutusPage.subHeading")}
            </h1>
            <p
              style={{
                textAlign: "justify",
                color: "black",
                fontWeight: 500,
                fontSize: "17px",
              }}
            >
              {t("aboutusPage.p1")}
            </p>
            <p
              style={{
                textAlign: "justify",
                color: "black",
                fontWeight: 500,
                fontSize: "17px",
              }}
            >
              {t("aboutusPage.p2")}
            </p>
            <p
              style={{
                textAlign: "justify",
                color: "black",
                fontWeight: 500,
                fontSize: "17px",
              }}
            >
              {t("aboutusPage.p3")}
            </p>
          </div>
          <div>
            <div style={webStyle.greenBorder}>
              <div style={webStyle.whiteBorder}>
                <img
                  src={agro}
                  className="aboutUsImage"
                  style={{
                    borderTopLeftRadius: "30%",
                    borderBottomRightRadius: "30%",
                    height: "475px",
                    width: "450px",
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          style={{ ...webStyle.ourVisionSection }}
          className="aboutUsContainer2"
          id="Mission&Vission"
        >
          <div
            style={{ width: "50%", marginRight: "10px" }}
            className="AboutUsTextContainer"
          >
            <h1 style={{ color: "green", fontSize: "40px", fontWeight: 600 }}>
              <span style={{ color: "#c0e61d" }}>{t("ourTeam.subHeading")}</span> {t("aboutusPage.vision")}
            </h1>
            <p style={{ color: "black", fontWeight: 500, fontSize: "17px" }}>
            {t("about.v")}
            </p>
            <ul
              style={{
                textAlign: "justify",
                color: "black",
                fontWeight: 500,
                fontSize: "17px",
              }}
            >
              <li>
                    <strong>{t("about.v1")}</strong> {t("about.v2")}
                  </li>
                  <li>
                    <strong>{t("about.v3")}</strong> {t("about.v4")}
                  </li>
                  <li>
                    <strong>{t("about.v5")}</strong> {t("about.v6")}
                  </li>
                  <li>
                    <strong>{t("about.v7")}</strong> {t("about.v8")}
                  </li>
            </ul>
          </div>
          <div>
            <div style={webStyle.greenBorder}>
              <div style={webStyle.whiteBorder}>
                <img
                  className="aboutUsImage"
                  src={visionagro}
                  style={{
                    borderTopLeftRadius: "30%",
                    borderBottomRightRadius: "30%",
                    height: "475px",
                    width: "450px",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ ...webStyle.ourVisionSection, flexDirection: "row-reverse" }}
          className="aboutUsContainer2"
          id="About-Company-History"
        >
          <div
            style={{ width: "50%", marginRight: "10px" }}
            className="AboutUsTextContainer"
          >
            <h1 style={{ color: "green", fontSize: "40px", fontWeight: 600 }}>
              <span style={{ color: "#c0e61d" }}>{t("ourTeam.subHeading")}</span> {t("aboutusPage.mission")}
            </h1>
            <p style={{ color: "black", fontWeight: 500, fontSize: "17px" }}>
            {t("about.m")}
            </p>
            <ul
              style={{
                textAlign: "justify",
                color: "black",
                fontWeight: 500,
                fontSize: "17px",
              }}
            >
               <li>
                    <strong>{t("about.m1")}</strong> {t("about.m2")}
                  </li>
                  <li>
                    <strong>{t("about.m3")}</strong>
                    {t("about.m4")}
                  </li>
                  <li>
                    <strong>{t("about.m5")}</strong> {t("about.m6")}
                  </li>
                  <li>
                    <strong>{t("about.m7")}</strong> {t("about.m8")}
                  </li>
            </ul>
          </div>
          <div>
            <div style={webStyle.greenBorder}>
              <div style={webStyle.whiteBorder}>
                <img
                  className="aboutUsImage"
                  src={missionagro}
                  style={{
                    borderTopLeftRadius: "30%",
                    borderBottomRightRadius: "30%",
                    height: "475px",
                    width: "450px",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ ...webStyle.ourVisionSection }}
          className="aboutUsContainer2"
        >
          <div
            style={{ width: "50%", marginRight: "10px" }}
            className="AboutUsTextContainer"
          >
            <h1 style={{ color: "green", fontSize: "40px", fontWeight: 600 }}>
              <span style={{ color: "#c0e61d" }}>{t("ourTeam.subHeading")}</span> {t("aboutusPage.promises")}
            </h1>
            <p style={{ color: "black", fontWeight: 500, fontSize: "17px" }}>{t("about.p")}</p>
                <ul   style={{
                textAlign: "justify",
                color: "black",
                fontWeight: 500,
                fontSize: "17px",
              }}>
                  <li>
                    <strong>{t("about.p1")}</strong> {t("about.p2")}
                  </li>
                  <li>
                    <strong>{t("about.p3")}</strong> {t("about.p4")}
                  </li>
                  <li>
                    <strong>{t("about.p5")}</strong> {t("about.p6")}
                  </li>
                  <li>
                    <strong>{t("about.p7")}</strong> {t("about.p8")}
                  </li>
                </ul>
          </div>
          <div>
            <div style={webStyle.greenBorder}>
              <div style={webStyle.whiteBorder}>
                <img
                  className="aboutUsImage"
                  src={ourPromise}
                  style={{
                    borderTopLeftRadius: "30%",
                    borderBottomRightRadius: "30%",
                    height: "475px",
                    width: "450px",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ ...webStyle.ourVisionSection, flexDirection: "row-reverse" }}
          className="aboutUsContainer2"
          id="About-Company-History"
        >
          <div
            style={{ width: "50%", marginRight: "10px" }}
            className="AboutUsTextContainer"
          >
                <Typography
              variant="h2"
              className={
                `${classes.heading} ${classes.historyHeading}` + "gsapAboutText"
              }
            >
              {/* <ReactTyped strings={[t("about.Name")]} typeSpeed={100} loop /> */}
              <span style={{color: "#99cc00", fontSize: "46px", fontWeight: 600 }}>{t("about.whyUs")} </span>
              <ReactTyped style={{color: "#339966", textDecoration: "underline", fontSize: "26px", fontWeight: 600}} 
              strings={[t("about.whyUsH")]} typeSpeed={100} loop />
            </Typography>
            <ul  style={{
                textAlign: "justify",
                color: "black",
                fontWeight: 500,
                fontSize: "17px",
              }}>
                <li>
                <strong>{t("about.whyusline1")}</strong>
                </li>
                <li>
                  <strong>{t("about.whyusline2")}</strong>
                </li>
                <li>
                  <strong>{t("about.whyusline3")}</strong>
                </li>
                <li>
                  <strong>{t("about.whyusline4")}</strong>
                </li>
                <li>
                  <strong>{t("about.whyusline5")}</strong>
                </li>
                <li>
                  <strong>{t("about.whyusline6")}</strong>
                </li>
                <li>
                  <strong>{t("about.whyusline7")}</strong>
                  <p>{t("about.whyusline8")}</p>
                </li>
              </ul>
          </div>
          <div>
            <div style={webStyle.greenBorder}>
              <div style={webStyle.whiteBorder}>
                <img
                  className="aboutUsImage"
                  src={Banner2}
                  style={{
                    borderTopLeftRadius: "30%",
                    borderBottomRightRadius: "30%",
                    height: "475px",
                    width: "450px",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <HistoryCarousel members={ourHistory} />
        <GalleryPage />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "80px 0 50px 0",
          }}
        >
          <iframe
            width="800"
            height="400"
            src="https://www.youtube.com/embed/hWLAqjtrlQA"
          ></iframe>
        </div>

        <WhatsAppButton phoneNumber="+919823986199" />
        <Footer />
      </div>
    </div>
  );
};

export default AboutUsPage;
