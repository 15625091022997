import React, { useEffect, useState } from "react";
import "./HistoryCarousel.css";
import { useTranslation } from "react-i18next";

export interface CarouselProps {
  members: {
    image: string;
    name: string;
    description: string;
  }[];
}

const HistoryCarousel: React.FC<CarouselProps> = ({ members }) => {
  const { t } = useTranslation();
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % members.length);
  };

  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + members.length) % members.length
    );
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, 10000);
    return () => clearInterval(interval);
  }, []);

  const currentSlides = members.slice(currentIndex, currentIndex + 3);
  const displayedSlides =
    currentSlides.length < 3
      ? currentSlides.concat(members.slice(0, 3 - currentSlides.length))
      : currentSlides;

  const currentSlidesMobile = members.slice(currentIndex, currentIndex + 1);
  const displayedSlidesMobile =
    currentSlidesMobile.length < 1
      ? currentSlidesMobile.concat(
          members.slice(0, 1 - currentSlidesMobile.length)
        )
      : currentSlidesMobile;

  useEffect(() => {
    const slideInterval = setInterval(() => {
      nextSlide();
    }, 8000);

    return () => clearInterval(slideInterval);
  }, []);

  return (
    <div className="carousel10 bgImage">
      <div>
        <h1
          style={{
            textAlign: "center",
            textTransform: "capitalize",
            marginTop: "15px",
            fontSize: "50px",
            fontWeight: 700,
            color: "#00A550",
            marginBottom: "35px",
          }}
        >
          <span style={{ color: "#BED630" }}>{t("about.our")}</span> {t("about.award")}
        </h1>
      </div>

      <div className="desktopView">
      <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "50%",
            }}
            className="arrowHover1"
          >
            <div style={{
              cursor: "pointer",
              fontSize: "48px",
              color: "black",
              marginBottom: "5px"
            }} className="scroll-arrow" id="scroll-left" onClick={prevSlide}>
              &#8592;
            </div>
          </div>
        {displayedSlides.map((member, index) => (
          <div
            className={`slide10 ${index === 1 ? "active10" : "inactive10"}`}
            key={index}
          >
            {index === 1 ? (
              <div className="color-wrapper">
                <div className="bg-shape"></div>
                <img
                  src={member.image}
                  alt={`Slide ${index + 1}`}
                  style={{
                    borderRadius: "8px",
                    border: "5px solid white",
                    padding: "0px",
                    filter: "grayscale(0%)",
                    display: "inline",
                  }}
                  className={`image10 color10`}
                />
                <h3
                  style={{
                    color: "#ffa500",
                    position: "relative",
                    fontSize: "70px",
                    fontWeight: 900,
                  }}
                >
                  {member.name}
                </h3>
                <p
                  style={{
                    fontWeight: 700,
                    width: "400px",
                    textAlign: "center",
                    color: "white",
                    position: "relative",
                    bottom: "7px",
                  }}
                >
                  {member.description}
                </p>
              </div>
            ) : (
              <>
                <img
                  src={member.image}
                  alt={`Slide ${index + 1}`}
                  className={`image10 bw10`}
                />
                <div className="member-info10 bw10">
                  <h3
                    style={{
                      color: "#ffa500",
                      fontSize: "110px",
                      fontWeight: 900,
                    }}
                  >
                    {member.name}
                  </h3>
                  <p
                    style={{
                      fontWeight: 700,
                      width: "400px",
                      textAlign: "center",
                      display: "none",
                    }}
                  >
                    {member.description}
                  </p>
                </div>
              </>
            )}
          </div>
        ))}
        <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "50%",
            }}
            className="arrowHover1"
          >
            <div style={{
              cursor: "pointer", fontSize: "48px", color: "black",
              marginBottom: "5px"
            }} className="scroll-arrow" id="scroll-right" onClick={nextSlide}>
              &#8594;
            </div>
          </div>
      </div>

      <div className="MobileView">
        <button className="prev10" onClick={prevSlide}>
          ❮
        </button>
        {displayedSlidesMobile.map((member, index) => (
          <div
            className="slide"
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
            }}
            key={index}
          >
            <img
              src={member.image}
              style={{ width: "90%", height: "auto" }}
              alt={`Slide ${index + 1}`}
              className="image"
            />
            <div className="member-info" style={{ textAlign: "center" }}>
              <h3 style={{ color: "#daa520" }}>{member.name}</h3>
              <p style={{ fontWeight: 700 }}>{member.description}</p>
            </div>
          </div>
        ))}
        <button className="next10" onClick={nextSlide}>
          ❯
        </button>
      </div>
    </div>
  );
};

export default HistoryCarousel;
