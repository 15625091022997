import React from "react";
import "../Our Team/Team.css";
import { Team1, Team2, Team3, Team4 } from "../../Assets.tsx";
import { useTranslation } from "react-i18next";

const Team = () => {
  const { t } = useTranslation();

  const webStyle = {
    Heading: {
      paddingTop: "40px",
      fontSize: "50px",
      fontWeight: 700,
      color: "#00A550",
      marginBottom: "10px",
    },
    subHeading: {
      fontSize: "30px",
      textAlign: "center",
      fontWeight: 700,
    },
    card: {
      background: "white",
      height: "280px",
      width:"520px",
      boxShadow:
        "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
      display: "flex",
      borderRadius:"8px "
    },
    imageSize: {
      width: "50%",
      // height:"365px"
    },
    cardsParentContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-evenly",
      flexWrap:"wrap"
    },
    CardsInfosection: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      padding: "0 8px",
    },
    NameStyle: {
      fontSize: "20px",
      color: "black",
      fontWeight: 500,
    },
  };
  return (
    <div className="TeamImageBg" id="our-team">
      <h1
        style={{
          ...webStyle.Heading,
          textTransform: "capitalize",
          textAlign: "center",
        }}
      >
        <span style={{ color: "#bed630" }}>{t('ourTeam.subHeading')}</span> {t('ourTeam.Heading')}
      </h1>
      <p style={{ ...webStyle.subHeading, textAlign: "center" }}>
      {t('ourTeam.p')}
      </p>
      <div style={{...webStyle.cardsParentContainer,flexWrap:"wrap"}}>
      <div style={webStyle.card} className="OurTeamImages CardsStyles">
          <img
            src={Team1}
            style={webStyle.imageSize}
            className="OurTeamImagesSize"
          />
          <div
            style={{ ...webStyle.CardsInfosection, flexDirection: "column" }}
          >
            <p style={webStyle.NameStyle}>
            {t('ourTeam.Team1')}
            </p>
            <span style={{ fontSize: "14px", color: "grey" }}>
            {t('ourTeam.position1')}
            </span>
          </div>
        </div>
      </div>
     

      <div style={{...webStyle.cardsParentContainer,marginTop:"20px",flexWrap:"wrap"}}>
      <div style={webStyle.card} className="CardsStyles OurTeamImages">
          <img
          src={Team3}
            style={webStyle.imageSize}
            className="OurTeamImagesSize"
          />
          <div
            style={{ ...webStyle.CardsInfosection, flexDirection: "column" }}
          >
             <p style={webStyle.NameStyle}>
            {t('ourTeam.Team3')}
            </p>
            <span style={{ fontSize: "14px", color: "grey" }}>
            {t('ourTeam.position3')}
            </span>
          </div>
        </div>
        <div style={webStyle.card} className="CardsStyles OurTeamImages OurTeamImagesMargin">
          <img
            src={Team2}
            style={webStyle.imageSize}
            className="OurTeamImagesSize"
          />
          <div
            style={{ ...webStyle.CardsInfosection, flexDirection: "column" }}
          >
             <p style={webStyle.NameStyle}>
            {t('ourTeam.Team2')}
            </p>
            <span style={{ fontSize: "14px", color: "grey" }}>
            {t('ourTeam.position2')}
            </span>
          </div>
        </div>
      </div>
      <div style={{...webStyle.cardsParentContainer,marginTop:"20px",flexWrap:"wrap"}}>
      {/* <div style={webStyle.card} className="CardsStyles OurTeamImages OurTeamImagesMargin ">
          <img
          src={Team4}
          style={webStyle.imageSize}
          className="OurTeamImagesSize"
          />
          <div
            style={{ ...webStyle.CardsInfosection, flexDirection: "column" }}
          >
           <p style={webStyle.NameStyle}>
            {t('ourTeam.Team4')}
            </p>
            <span style={{ fontSize: "14px", color: "grey" }}>
            {t('ourTeam.position4')}
            </span>
          </div>
        </div> */}
      {/* <div style={webStyle.card} className="CardsStyles OurTeamImages">
          <img
            src={Team1}
            style={webStyle.imageSize}
            className="OurTeamImagesSize"
          />
          <div
            style={{ ...webStyle.CardsInfosection, flexDirection: "column" }}
          >
           <p style={webStyle.NameStyle}> */}
            {/* {t('ourTeam.Team1')} */}
            {/* Shivanjali Patil */}
            {/* </p>
            <span style={{ fontSize: "14px", color: "grey" }}> */}
            {/* {t('ourTeam.position1')} */}
            {/* Account Lead
            </span> */}
          {/* </div>
        </div> */}
        
      </div>
    </div>
  );
};

export default Team;
