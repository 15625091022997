import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import { Logo } from "../Assets.tsx";
import "./Navbar.css";
import LanguageSelector from "./LanguageSelector.tsx";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import Template from "./Template/Template.tsx";

const PDF_FILE_URL = "/water.pdf";
const n = "/n.pdf";
const UBC = "/UBC.pdf";
const catalog = "/CATALOG.pdf";
const scheme = "/Scheme.pdf";
const canestar = "/Canestar.pdf";

const Navbar = () => {
  useGSAP(() => {
    gsap.from(".gsaplogo", {
      y: -30,
      opacity: 0,
      duration: 1,
      delay: 1,
    });
  });

  useGSAP(() => {
    gsap.from(".gsapRightSideNav", {
      y: -30,
      opacity: 0,
      duration: 1,
      stagger: 0.3,
      delay: 1,
    });
  });

  const viewPDF = (url) => {
    window.open(url, "_blank");
  };
  const [isSidebarVisible, setSidebarVisibility] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const { t } = useTranslation();

  const openMenu = () => {
    setSidebarVisibility(!isSidebarVisible);
    document.body.classList.toggle("no-scroll", !isSidebarVisible);
  };

  const history = useHistory();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, []);

  const webStyle = {
    container: {
      backgroundColor: "#1F1F1F",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
      padding: "10px 0 0 0",
      height: "auto",
      width: "100%",
      zIndex: "1111",
    },
    MenuButtonStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      background: "white",
      border: "none",
      color: "black",
    },
    logo: {
      height: "80px",
      cursor: "pointer",
      // paddingBottom: "8px",
      borderRadius: "8px",
    },
    searchBar: {
      width: "100%",
      height: "40px",
      borderRadius: "5px",
      border: "2px solid #be9449",
    },
    options: {
      padding: "15px",
      fontWeight: 600,
      borderBottom: "1px solid grey",
      cursor: "pointer",
    },
    options2: {
      padding: "15px",
      fontWeight: 600,
      borderBottom: "1px solid grey",
      cursor: "pointer",
      color: "white",
    },
    optionsDiv: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      gap: "12px",
    },
    formLogo: {
      width: "80px",
      height: "80px",
      borderRadius: "50%",
      display: "flex",
      alignSelf: "center",
      marginBottom: "20px",
    },
    formDiv: {
      display: "flex",
      flexDirection: "column",
    },
    sendOtpButton: {
      color: "white",
      padding: "5px",
      background: "#565e6b",
      borderRadius: "3px",
    },
    enterOtpInput: {
      marginBottom: "10px",
      padding: "10px",
      borderRadius: "3px",
    },
    HeadingOption: {
      marginRight: "20px",
      cursor: "pointer",
      textDecoration: "none",
      color: isScrolled ? "white" : "black",
    },
    dropdown: {
      position: "absolute",
      backgroundColor: "white",
      border: "1px solid #ccc",
      width: "100%",
      maxHeight: "200px",
      overflowY: "auto",
      zIndex: 1000,
      textDecoration: "none",
    },
    dropdownItem: {
      padding: "10px",
      cursor: "pointer",
      display: "flex",
      textTransform: "capitalize",
      alignItems: "center",
      fontSize: "14px",
    },
    itemImage: {
      width: "40px",
      height: "40px",
      marginRight: "10px",
    },
  };

  const handleHomeclick = () => {
    setSidebarVisibility(false);
    history.push("/");
  };
  const handleProductclick = () => {
    setSidebarVisibility(false);
    history.push("/products");
  };
  const handleAboutusclick = () => {
    setSidebarVisibility(false);
    history.push("/About-us");
  };
  const handleAwardclick = () => {
    setSidebarVisibility(false);
    history.push("/awards");
  };
  const [openSection, setOpenSection] = useState(null);

  const handleSectionClick = (sectionName) => {
    setOpenSection((prevOpenSection) =>
      prevOpenSection === sectionName ? null : sectionName
    );
  };
  const handleContact = () => {
    window.location.href = "tel:+919823986199";
  };
  const handleEmail = () => {
    window.location.href = "mailto:universalbioconpvt.ltd@gmail.com";
  };
  const HandleWhatsApp = () => {
    const phoneNumber = "+919823986199";
    const url = `https://wa.me/${phoneNumber}`;
    window.open(url, "_blank");
  };
  const PDF = () => {
    window.print();
  };

  const handleMouseEnter = (section) => {
    setHover((prevHover) => ({ ...prevHover, [section]: true }));
  };

  const handleMouseLeave = (section) => {
    setHover((prevHover) => ({ ...prevHover, [section]: false }));
  };

  const [hover, setHover] = useState({
    products: false,
    AboutUs: false,
    contactUs: false,
    Downloads: false,
  });

  return (
    <div>
<Template />
    <div
      className="NavbarContainer"
      style={{
        ...webStyle.container,
        position: "fixed",
        top:"0",
        backgroundColor: isScrolled ? "rgba(52, 163, 41, 0.77)" : "transparent",
        color: isScrolled ? "white" : "black",
        marginTop: isScrolled ? "0px" : "30px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "58%",
          justifyContent: "space-between",
          padding: "0 40px",
        }}
        className="navbarLogoContainer"
      >
        <img
          onClick={() => history.push("/")}
          src={Logo}
          style={webStyle.logo}
          className="logo gsaplogo"
          alt="logo"
        />
      </div>
      <Box
        className="rightSideNavOptions gsapRightSideNav"
        style={{ ...webStyle.optionsDiv, padding: "0 5px" }}
      >
        <div className="dropdown">
          <span
            onClick={() => history.push("/")}
            className="items RouteOptions"
            style={webStyle.HeadingOption}
          >
            {t("navbar.home")}
          </span>
        </div>
        <li className="dropdown2">
          <a
            className="items RouteOptions gsapRightSideNav"
            style={{
              ...webStyle.HeadingOption,
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => {
              history.push("/products");
            }}
            onMouseEnter={() => handleMouseEnter("products")}
            onMouseLeave={() => handleMouseLeave("products")}
          >
            <span>{t("navbar.products")}</span>
            {hover.products ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </a>
          <ul
            className="dropdown-menu2"
            onMouseEnter={() => handleMouseEnter("products")}
            onMouseLeave={() => handleMouseLeave("products")}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                width: "100%",
              }}
            >
              <div style={{ width: "100%" }}>
              <li className="dropdown-submenu2">
                  <a
                    href="#Bio-Fungicides"
                    onClick={() => {
                      history.push("/products");
                    }}
                  >
                    {t("navbar.BioFungicides")} <i className="fas fa-caret-right"></i>
                  </a>
                </li>
                <li className="dropdown-submenu2">
                  <a
                    href="#Bio-insecticides"
                    onClick={() => {
                      history.push("/products");
                    }}
                  >
                    {t("navbar.BioInsecticides")}{" "}
                    <i className="fas fa-caret-right right-icon"></i>
                  </a>
                </li>
                <li className="dropdown-submenu2">
                  <a
                    href="#Bio-kit"
                    onClick={() => {
                      history.push("/products");
                    }}
                  >
                    {t("navbar.BioKit")} <i className="fas fa-caret-right"></i>
                  </a>
                </li>
                <li className="dropdown-submenu2">
                  <a
                    href="#Bio-Fertilizers"
                    onClick={() => {
                      history.push("/products");
                    }}
                  >
                    {t("navbar.BioFertilizers")} <i className="fas fa-caret-right"></i>
                  </a>
                </li>
                <li className="dropdown-submenu2">
                  <a
                    href="#Botanic-Extract-Insecticides"
                    onClick={() => {
                      history.push("/products");
                    }}
                  >
                    {t("navbar.BEI")}
                    <i className="fas fa-caret-right"></i>
                  </a>
                </li>
              </div>
              <div style={{ width: "100%" }}>
                <li className="dropdown-submenu2">
                  <a
                    href="#Chemical-Fungicides"
                    onClick={() => {
                      history.push("/products");
                    }}
                  >
                    {t("navbar.ChemicalFungicides")}<i className="fas fa-caret-right"></i>
                  </a>
                </li>
                <li className="dropdown-submenu2">
                  <a
                    href="#Chemical-Insecticides"
                    onClick={() => {
                      history.push("/products");
                    }}
                  >
                    {t("navbar.ChemicalInsecticides")}<i className="fas fa-caret-right"></i>
                  </a>
                </li>
                <li className="dropdown-submenu2">
                  <a
                    href="#Micro-Nutrients"
                    onClick={() => {
                      history.push("/products");
                    }}
                  >
                    {t("navbar.microNutrients")} <i className="fas fa-caret-right"></i>
                  </a>
                </li>
                <li className="dropdown-submenu2">
                  <a
                    href="#PGR"
                    onClick={() => {
                      history.push("/products");
                    }}
                  >
                    {t("navbar.pgr")}<i className="fas fa-caret-right"></i>
                  </a>
                </li>
                <li className="dropdown-submenu2">
                  <a
                    href="#algacide"
                    onClick={() => {
                      history.push("/products");
                    }}
                  >
                    {t("navbar.algacide")}<i className="fas fa-caret-right"></i>
                  </a>
                </li>
              </div>
              <div style={{ width: "100%" }}>
               
                <li className="dropdown-submenu2">
                  <a
                    href="#Sticker-Spreader"
                    onClick={() => {
                      history.push("/products");
                    }}
                  >
                    {t("navbar.Stsp")}<i className="fas fa-caret-right"></i>
                  </a>
                </li>
              </div>
            </div>
          </ul>
        </li>

        <li className="dropdown">
          <a
            href="#aboutus"
            className="items RouteOptions gsapRightSideNav"
            style={{
              ...webStyle.HeadingOption,
              display: "flex",
              alignItems: "center",
            }}
            onMouseEnter={() => handleMouseEnter("AboutUs")}
            onMouseLeave={() => handleMouseLeave("AboutUs")}
          >
            <span>{t("navbar.about_us")}</span>
            {hover.AboutUs ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </a>
          <ul
            className="dropdown-menu"
            onMouseEnter={() => handleMouseEnter("AboutUs")}
            onMouseLeave={() => handleMouseLeave("AboutUs")}
          >
            <li className="dropdown-submenu">
              <a
                onClick={() => {
                  history.push("About-us");
                }}
                href="#About-Company-History"
              >
                {t("navbar.About Company History")}{" "}
                <i className="fas fa-caret-right right-icon"></i>
              </a>
            </li>
            <li className="dropdown-submenu">
              <a
                onClick={() => {
                  history.push("About-us");
                }}
                href=""
              >
                {t("navbar.Universal Bio")} <i className="fas fa-caret-right"></i>
              </a>
            </li>
            <li className="dropdown-submenu">
              <a
                onClick={() => {
                  history.push("About-us");
                }}
                href="#Mission&Vission"
              >
                {t("navbar.Mission And Vision")} <i className="fas fa-caret-right"></i>
              </a>
            </li>
            <li className="dropdown-submenu">
              <a
                onClick={() => {
                  history.push("/");
                }}
                href="#our-team"
              >
                {t("navbar.Our Team")} <i className="fas fa-caret-right"></i>
              </a>
            </li>
            <li className="dropdown-submenu">
              <a
                onClick={() => {
                  history.push("/awards");
                }}
                href=""
              >
                {t("navbar.Awards and Achievements")}{" "}
                <i className="fas fa-caret-right"></i>
              </a>
            </li>
            <li className="dropdown-submenu">
              <a
                onClick={() => {
                  history.push("About-us");
                }}
                href=""
              >
                {t("navbar.Gallery")} <i className="fas fa-caret-right"></i>
              </a>
            </li>
          </ul>
        </li>

        <li className="dropdown">
          <a
            href=""
            className="items RouteOptions gsapRightSideNav"
            style={{
              ...webStyle.HeadingOption,
              display: "flex",
              alignItems: "center",
            }}
            onMouseEnter={() => handleMouseEnter("contactUs")}
            onMouseLeave={() => handleMouseLeave("contactUs")}
          >
            <span>{t("navbar.contact_us")}</span>
            {hover.contactUs ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </a>
          <ul
            className="dropdown-menu"
            onMouseEnter={() => handleMouseEnter("contactUs")}
            onMouseLeave={() => handleMouseLeave("contactUs")}
          >
            <li className="dropdown-submenu">
              <a onClick={handleContact} href="">
              {t("navbar.Call")} <i className="fas fa-caret-right right-icon"></i>
              </a>
            </li>
            <li className="dropdown-submenu">
              <a onClick={HandleWhatsApp} href="">
              {t("navbar.Whatsapp")} <i className="fas fa-caret-right"></i>
              </a>
            </li>
            <li className="dropdown-submenu">
              <a onClick={handleEmail} href="">
              {t("navbar.Email")} <i className="fas fa-caret-right"></i>
              </a>
            </li>
          </ul>
        </li>

        <li className="dropdown">
          <a
            href=""
            className="items RouteOptions gsapRightSideNav"
            style={{
              ...webStyle.HeadingOption,
              display: "flex",
              alignItems: "center",
            }}
            onMouseEnter={() => handleMouseEnter("Downloads")}
            onMouseLeave={() => handleMouseLeave("Downloads")}
          >
            <span>{t("navbar.downloads")}</span>
            {hover.Downloads ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </a>
          <ul
            className="dropdown-menu"
            onMouseEnter={() => handleMouseEnter("Downloads")}
            onMouseLeave={() => handleMouseLeave("Downloads")}
          >
            <li className="dropdown-submenu">
              <a href="">
              {t("navbar.MBrochure")} <i className="fas fa-caret-right"></i>
              </a>
              <ul className="dropdown-menu">
                <li
                  onClick={() => viewPDF(PDF_FILE_URL)}
                  className="items RouteOptions"
                  style={webStyle.HeadingOption}
                >
                  <a href="#products">{t("navbar.water")} </a>
                </li>
                <li
                  onClick={() => viewPDF(n)}
                  className="items RouteOptions"
                  style={webStyle.HeadingOption}
                >
                  <a href="#products">{t("navbar.Micro")}</a>
                </li>
                <li
                  onClick={() => viewPDF(canestar)}
                  className="items RouteOptions"
                  style={webStyle.HeadingOption}
                >
                  <a href="#products"> {t("navbar.Kit")}</a>
                </li>
              </ul>
            </li>
            <li className="dropdown-submenu">
              <a href="">
              {t("navbar.EBrochure")} <i className="fas fa-caret-right"></i>
              </a>
              <ul className="dropdown-menu">
                <li
                  onClick={() => viewPDF(UBC)}
                  className="items RouteOptions"
                  style={webStyle.HeadingOption}
                >
                  <a href="#products">   {t("navbar.UBCP")}</a>
                </li>
                <li
                  onClick={() => viewPDF(catalog)}
                  className="items RouteOptions"
                  style={webStyle.HeadingOption}
                >
                  <a href="#products">{t("navbar.Catalog")}</a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <div className="kisan-shopee-container">
          <button
            onClick={() => window.open("https://kisanshopee.com/", "_blank", "noopener,noreferrer")}
            className="kisan-shopee-btn"
          >
            <svg width="180px" height="60px" viewBox="0 0 180 60">
              <polyline points="179,1 179,59 1,59 1,1 179,1" />
            </svg>
            <span>{t("navbar.kisan")}</span>
          </button>
        </div>
      </Box>
      <Box className="item  humburger-menu">
        <MenuIcon
          onClick={openMenu}
          style={{
            fontSize: "35px",
            marginRight: "20px",
            color: "white",
          }}
        />
      </Box>
      {/* Hamburger Start */}
      {isSidebarVisible && (
        <Box className="sidebar">
          <div
            style={{
              display: "flex",
              alignContent: "center",
              justifyContent: "space-between",
              width: "100%",
              margin: "12px",
            }}
          >
            <img
              onClick={() => history.push("/")}
              src={Logo}
              style={webStyle.logo}
              className="logo"
              alt="logo"
            />
            <CloseIcon
              onClick={() => {
                setSidebarVisibility(false);
                document.body.classList.remove("no-scroll");
              }}
              style={{ marginRight: "15px", fontSize: "50px", color: "white" }}
            />
          </div>

          <Box style={{ width: "100%" }}>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                lineHeight: "2.5",
                width: "100%",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <div style={{ width: "100%" }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <a
                      style={{
                        ...webStyle.HeadingOption,
                        fontWeight: 600,
                        borderBottom: "1px solid grey",
                        padding: "15px",
                        margin: "0",
                        color: "white",
                        textDecoration: "none",
                      }}
                      href="#Menu"
                    >
                      <span
                        onClick={() => {
                          handleHomeclick();
                          setSidebarVisibility(false);
                          document.body.classList.remove("no-scroll");
                        }}
                        className=" RouteOptions "
                      >
                        Home{" "}
                      </span>
                    </a>
                    <p
                      onClick={() => handleSectionClick("Products")}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "rgb(27, 27, 27)",
                        color: "white",
                        justifyContent: "space-between",
                        padding: "15px",
                        border: "none",
                        borderBottom: "1px solid grey",
                        margin: "0",
                      }}
                    >
                      <div>
                        <span style={{ fontWeight: 600 }}> Products</span>
                      </div>
                      <span>
                        {openSection === "Products" ? (
                          <KeyboardArrowUpIcon style={{ fontSize: "15px" }} />
                        ) : (
                          <KeyboardArrowDownIcon style={{ fontSize: "15px" }} />
                        )}
                      </span>
                    </p>
                    {openSection === "Products" && (
                      <div
                        style={{ display: "flex", flexDirection: "column" }}
                        className="scrollable-section"
                      >
                        <a
                          href="#Bio-Fungicides"
                          className=" options"
                          style={{
                            ...webStyle.options2,
                            textDecoration: "none",
                          }}
                          onClick={handleProductclick}
                        >
                          Bio Fungicides
                        </a>
                        <a
                          href="#Bio-kit"
                          className=" options"
                          style={{
                            ...webStyle.options2,
                            textDecoration: "none",
                          }}
                          onClick={handleProductclick}
                        >
                          Bio Kit
                        </a>
                        <a
                          href="#Bio-Fertilizers"
                          className=" options"
                          style={{
                            ...webStyle.options2,
                            textDecoration: "none",
                          }}
                          onClick={handleProductclick}
                        >
                          Bio Fertilizers
                        </a>
                        
                      
                        
                        <a
                          href="#Bio-insecticides"
                          className=" options"
                          style={{
                            ...webStyle.options2,
                            textDecoration: "none",
                          }}
                          onClick={handleProductclick}
                        >
                          Bio Insecticides
                        </a>
                        <a
                          href="#Micro-Nutrients"
                          className=" options"
                          style={{
                            ...webStyle.options2,
                            textDecoration: "none",
                          }}
                          onClick={handleProductclick}
                        >
                          Micro Nutrients 
                        </a>
                        <a
                          href="#algacide"
                          className=" options"
                          style={{
                            ...webStyle.options2,
                            textDecoration: "none",
                          }}
                          onClick={handleProductclick}
                        >
                           Algacide
                        </a>
                        <a
                          href="#Chemical-Insecticides"
                          className=" options"
                          style={{
                            ...webStyle.options2,
                            textDecoration: "none",
                          }}
                          onClick={handleProductclick}
                        >
                           Chemical Insecticides
                        </a>
                        <a
                          href="#Sticker-Spreader"
                          className=" options"
                          style={{
                            ...webStyle.options2,
                            textDecoration: "none",
                          }}
                          onClick={handleProductclick}
                        >
                           Sticker & Spreader
                        </a>
                        <a
                          href="#PGR"
                          className=" options"
                          style={{
                            ...webStyle.options2,
                            textDecoration: "none",
                          }}
                          onClick={handleProductclick}
                        >
                           PGR
                        </a>
                        <a
                          href="#Botanic-Extract-Insecticides"
                          className=" options"
                          style={{
                            ...webStyle.options2,
                            textDecoration: "none",
                          }}
                          onClick={handleProductclick}
                        >
                           BEI
                        </a>
                        <a
                          href="#Chemical-Fungicides"
                          className=" options"
                          style={{
                            ...webStyle.options2,
                            textDecoration: "none",
                          }}
                          onClick={handleProductclick}
                        >
                           Chemical Fungicides
                        </a>
                      </div>
                    )}{" "}
                    <p
                      onClick={() => handleSectionClick("About us")}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "rgb(27, 27, 27)",
                        color: "white",
                        justifyContent: "space-between",
                        padding: "15px",
                        border: "none",
                        borderBottom: "1px solid grey",
                        margin: "0",
                      }}
                    >
                      <div>
                        <span style={{ fontWeight: 600 }}> About us</span>
                      </div>
                      <span>
                        {openSection === "About us" ? (
                          <KeyboardArrowUpIcon style={{ fontSize: "15px" }} />
                        ) : (
                          <KeyboardArrowDownIcon style={{ fontSize: "15px" }} />
                        )}
                      </span>
                    </p>
                    {openSection === "About us" && (
                      <div
                        style={{ display: "flex", flexDirection: "column" }}
                        className="scrollable-section"
                      >
                        <a
                          href="#About-Company-History"
                          className="options"
                          style={{
                            ...webStyle.options2,
                            textDecoration: "none",
                          }}
                          onClick={handleAboutusclick}
                        >
                          About Company History
                        </a>
                        <a
                          className="options"
                          style={{
                            ...webStyle.options2,
                            textDecoration: "none",
                          }}
                          onClick={handleAboutusclick}
                        >
                          Universal Bio
                        </a>
                        <a
                          href="#Mission&Vission"
                          className="options"
                          style={{
                            ...webStyle.options2,
                            textDecoration: "none",
                          }}
                          onClick={handleAboutusclick}
                        >
                          Mission And Vision
                        </a>
                        <a
                          className="options"
                          style={{
                            ...webStyle.options2,
                            textDecoration: "none",
                          }}
                          onClick={handleAboutusclick}
                        >
                          Our Team
                        </a>
                        <a
                          className="options"
                          style={{
                            ...webStyle.options2,
                            textDecoration: "none",
                          }}
                          onClick={handleAwardclick}
                        >
                          Awards & Achievements
                        </a>
                        <a
                          href="#Gallery"
                          className="options"
                          style={{
                            ...webStyle.options2,
                            textDecoration: "none",
                          }}
                          onClick={handleAboutusclick}
                        >
                          Gallery
                        </a>
                      </div>
                    )}{" "}
                    <a
                      style={{
                        ...webStyle.HeadingOption,
                        fontWeight: 600,
                        borderBottom: "1px solid grey",
                        padding: "15px",
                        margin: "0",
                        color: "white",
                        textDecoration: "none",
                      }}
                      onClick={() => {
                        setSidebarVisibility(false);
                        document.body.classList.remove("no-scroll");
                      }}
                      href="#Reservation"
                    >
                      <span className=" RouteOptions ">Downloads</span>
                    </a>
                  </div>
                </div>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      <LanguageSelector />
    </div>
    </div>
  
);
};

export default Navbar;
