export const Logo = require("../src/Assets/logo.png");
export const FooterImg = require("../src/Assets/Footer.jpeg");
export const Wheat_bg = require("../src/Assets/Wheat_bggg.png");
export const FooterImge = require("../src/Assets/FooterImage.jpeg");
export const Footerbg = require("../src/Assets/Footer2.png");
export const bg = require("../src/Assets/bg.png");
export const leaf1 = require("./Assets/wheat1.png");
export const leaf2 = require("./Assets/wheat1.png");
export const leaf3 = require("./Assets/wheat1.png");
export const leaf4 = require("./Assets/wheat1.png");
export const Soilimg = require("./Assets/Soil_Product_Section.png");
export const bannerVideo = require("./Assets/Banner-Video1.mp4");
export const bannerTree = require("./Assets/Banner4.jpeg");
export const treeWallpaper = require("./Assets/tree-wallpaper.png");
export const Banner1 = require("./Assets/Banner1.jpeg");
export const Banner2 = require("./Assets/Banner2.jpeg");
export const Banner3 = require("./Assets/Banner3.png");
export const cropsWallpaper = require("./Assets/crops-background.png");
export const Product1 = require("./Assets/Bio-Trust.png");
export const Product2 = require("./Assets/Florin.png");
export const Product3 = require("./Assets/PlantGrow1.png");
export const Product4 = require("./Assets/Germinate-21.png");
export const Product5 = require("./Assets/All-Source-9-Bio-Kit.png");
export const Product6 = require("./Assets/Supremo.png");
export const Product7 = require("./Assets/Bactricell.png");
export const Product8 = require("./Assets/product112.png");
export const Product10 = require("./Assets/product31.png");
// export const Product12 = require("./Assets/product33.png");
export const Product13 = require("./Assets/product34.png");
export const Product15 = require("./Assets/product36.png");
export const Product16 = require("./Assets/product37.png");
export const Product17 = require("./Assets/bio-bullet.png");
export const Product18 = require("./Assets/product18-Photoroom.png");
export const Product19 = require("./Assets/product19-Photoroom.png");
export const cottonImg = require("./Assets/cotton-img.jpg");
export const ourMission = require("./Assets/our-vision.jpeg");
export const ourVision = require("./Assets/our-vision1.jpg");
export const ourPromise = require("./Assets/our-promise.jpg");
export const AboutUsBanner = require("./Assets/bg4.jpeg");
export const History = require("./Assets/History.png");
export const Award1 = require("./Assets/Award1.png");
export const Award2 = require("./Assets/Award2.png");
export const Award3 = require("./Assets/Award3.png");
export const Award4 = require("./Assets/Award4.png");
export const Award5 = require("./Assets/Award5.png");
export const agro = require("./Assets/agro.jpg");
export const visionagro = require("./Assets/visionagro.jpg");
export const missionagro = require("./Assets/growImage.jpg");
export const productBanner = require("./Assets/productBanner.png");
export const awardsBannerImg = require("./Assets/gallery4.jpeg");
export const awardsImg1 = require("./Assets/awads-img1.PNG");
export const awardsImg2 = require("./Assets/gallery2.jpeg");
export const awardsImg3 = require("./Assets/awads-img3.PNG");
export const awardsImg4 = require("./Assets/awads-img4.PNG");
export const awardsImg5 = require("./Assets/awads-img5.PNG");
export const awardsImg6 = require("./Assets/awads-img6.PNG");
export const awardsImg7 = require("./Assets/awads-img7.PNG");
export const awardsImg8 = require("./Assets/awads-img8.PNG");
export const awardsImg9 = require("./Assets/awads-img9.PNG");
export const awardsImg10 = require("./Assets/awads-img10.PNG");
export const aboutusHomeImg = require("./Assets/vishwas-aboutus-img.jpg");

export const plantGrow1 = require("./Assets/PlantGrow1.png");
export const plantGrow2 = require("./Assets/plantGrow2.webp");
export const product20 = require("./Assets/product30.png");
export const product21 = require("./Assets/product31.png");
export const product22 = require("./Assets/bio-bullet.png");
export const product23 = require("./Assets/Shool.png");
export const product24 = require("./Assets/product23.png");
export const product25 = require("./Assets/product24.png");
export const product26 = require("./Assets/product25.png");
export const product27 = require("./Assets/product26.png");
export const product28 = require("./Assets/product27.png");
export const product29 = require("./Assets/product28.png");
export const product30 = require("./Assets/product29.png");
export const product31 = require("./Assets/Organic.webp");
export const product32 = require("./Assets/Bactricell.png");
export const product33 = require("./Assets/product46.png");
export const product34 = require("./Assets/product34.png");
export const product35 = require("./Assets/Supremo.png");
export const product36 = require("./Assets/product36.png");
export const product37 = require("./Assets/product37.png");
export const product38 = require("./Assets/product38.png");
export const product39 = require("./Assets/product39.png");
export const product40 = require("./Assets/product40.png");

export const product41 = require("./Assets/product48.png");
export const product42 = require("./Assets/product42.png");
export const product43 = require("./Assets/product43.png");
export const product44 = require("./Assets/product44.png");
export const product45 = require("./Assets/product45.png");
export const product46 = require("./Assets/product50.png");
export const product47 = require("./Assets/product51.png");
export const product48 = require("./Assets/product52.png");
export const product49 = require("./Assets/product53.png");
export const product51 = require("./Assets/product54.png");
export const product52 = require("./Assets/product56.png");
export const product53 = require("./Assets/product57.png");
export const product54 = require("./Assets/product58.png");
export const product55 = require("./Assets/product59.png");
export const product56 = require("./Assets/product60.png");
export const product57 = require("./Assets/product61.png");
export const product58 = require("./Assets/product62.png");
export const product59 = require("./Assets/product63.png");
export const product60 = require("./Assets/product64.png");
export const product61 = require("./Assets/product55.png");
export const product62 = require("./Assets/product65.png");
export const product63 = require("./Assets/product74.png");
export const product64 = require("./Assets/product66.png");
export const product65 = require("./Assets/product67.png");
export const product66 = require("./Assets/product68.png");
export const product67 = require("./Assets/product69.png");
export const product68 = require("./Assets/product70.png");
export const product69 = require("./Assets/product71.png");
export const product70 = require("./Assets/product72.png");
export const product71 = require("./Assets/product73.png");
export const product72 = require("./Assets/product75.png");
export const product73 = require("./Assets/product76.png");
export const product74 = require("./Assets/product77.png");
export const product75 = require("./Assets/product78.png");
export const product76 = require("./Assets/product79.png");
export const product77 = require("./Assets/product80.png");
export const product78 = require("./Assets/product81.png");
export const product79 = require("./Assets/product82.png");
export const product80 = require("./Assets/product83.png");
export const product81 = require("./Assets/product84.png");
export const product82 = require("./Assets/product85.png");
export const product83 = require("./Assets/product86.png");
export const product84 = require("./Assets/product87.png");
export const product85 = require("./Assets/product88.png");
export const product86 = require("./Assets/product89.png");
export const product87 = require("./Assets/product90.png");
export const product88 = require("./Assets/product91.png");
export const product89 = require("./Assets/product92.png");
export const product90 = require("./Assets/product93.png");
export const product91 = require("./Assets/product94.png");
export const product92 = require("./Assets/product95.png");
export const product93 = require("./Assets/product96.png");
export const product94 = require("./Assets/product97.png");
export const product95 = require("./Assets/product98.png");
export const product96 = require("./Assets/product99.png");
export const product97 = require("./Assets/product100.png");
export const product98 = require("./Assets/product101.png");
export const product99 = require("./Assets/product102.png");
export const product100 = require("./Assets/product103.png");
export const product101 = require("./Assets/product104.png");
export const product102 = require("./Assets/product105.png");
export const product103 = require("./Assets/product106.png");
export const product104 = require("./Assets/product107.png");
export const product105 = require("./Assets/product108.png");
export const product106 = require("./Assets/product109.png");
export const product107 = require("./Assets/product110.png");
export const product108 = require("./Assets/product111.png");
export const product109 = require("./Assets/product112.png");
export const product110 = require("./Assets/product113.png");
export const product111 = require("./Assets/product114.png");
export const product112 = require("./Assets/product115.png");
export const product113 = require("./Assets/product116.png");
export const product114 = require("./Assets/product117.png");
export const product115 = require("./Assets/product118.png");
export const product116 = require("./Assets/product119.png");
export const product117 = require("./Assets/product120.png");
export const product118 = require("./Assets/product121.png");
export const product119 = require("./Assets/product122.png");
export const product120 = require("./Assets/product123.png");
export const product121 = require("./Assets/product124.png");
export const product122 = require("./Assets/product125.png");
export const product123 = require("./Assets/product126.png");
export const product124 = require("./Assets/product127.png");
export const product125 = require("./Assets/product128.png");
export const product126 = require("./Assets/product129.png");
export const product127 = require("./Assets/product130.png");
export const product128 = require("./Assets/product131.png");
export const product129 = require("./Assets/product132.png");
export const product130 = require("./Assets/product133.png");
export const product131 = require("./Assets/product134.png");
export const product132 = require("./Assets/product135.png");
export const product133 = require("./Assets/product136.png");
export const product134 = require("./Assets/product137.png");
export const product135 = require("./Assets/product138.png");
export const product136 = require("./Assets/product139.png");
export const product137 = require("./Assets/product140.png");
export const product138 = require("./Assets/product141.png");
export const product139 = require("./Assets/product142.png");
export const product140 = require("./Assets/product143.png");
export const product141 = require("./Assets/product144.png");
export const product142 = require("./Assets/product145.png");
export const product143 = require("./Assets/product146.png");
export const product144 = require("./Assets/product147.png");
export const product145 = require("./Assets/product148.png");
export const product146 = require("./Assets/product149.png");
export const product147 = require("./Assets/product150.png");
export const product148 = require("./Assets/product151.png");
export const product149 = require("./Assets/product152.png");
export const product150 = require("./Assets/product153.png");
export const product151 = require("./Assets/product154.png");
export const product152 = require("./Assets/product155.png");
export const product153 = require("./Assets/product156.png");
export const product154 = require("./Assets/product157.png");
export const product155 = require("./Assets/product158.png");
export const product156 = require("./Assets/product159.png");
export const product157 = require("./Assets/product160.png");
export const product158 = require("./Assets/product161.png");
export const product159 = require("./Assets/product162.png");
export const product160 = require("./Assets/product163.png");
export const product161 = require("./Assets/product164.png");
export const product162 = require("./Assets/Biosoul-Mix.png");
export const product163 = require("./Assets/product165.png");
export const product164 = require("./Assets/product166.png");
export const product165 = require("./Assets/product167.png");
export const product166 = require("./Assets/product168.png");
export const product167 = require("./Assets/product169.png");
export const product168 = require("./Assets/product170.png");
export const product169 = require("./Assets/product171.png");
export const product170 = require("./Assets/product172.png");
export const product171 = require("./Assets/product173.png");




























export const product50 = require("./Assets/product47.png");


export const wave= require("./Assets/wave.png");

export const Team1 = require("./Assets/Team11.png");
export const Team2 = require("./Assets/Team42.png");
export const Team3 = require("./Assets/Team32.png");
export const Team4 = require("./Assets/Team2.png");

export const Certificate1 = require("./Assets/Certificate1.png");
export const Certificate2 = require("./Assets/Certificate2.png");
export const Certificate3 = require("./Assets/Certificate3.png");
// export const Certificate4 = require("./Assets/Certificate4.png");
export const Certificate5 = require("./Assets/Certificate5.png");
export const Certificate6 = require("./Assets/Certificate6.png");
// export const Certificate7 = require("./Assets/Certificate7.png");
export const Certificate = require("./Assets/Certificate.png");

export const Gallery1 = require("./Assets/gallery1.jpeg");
export const Gallery2 = require("./Assets/gallery2.jpeg");
export const Gallery3 = require("./Assets/gallery3.jpeg");
export const Gallery4 = require("./Assets/gallery4.jpeg");
export const Gallery5 = require("./Assets/gallery5.jpeg");
export const Gallery6 = require("./Assets/gallery6.jpeg");
export const Gallery7 = require("./Assets/gallery7.jpeg");
export const Gallery8 = require("./Assets/gallery8.jpeg");
export const Gallery9 = require("./Assets/gallery9.jpeg");
export const Gallery10 = require("./Assets/gallery10.jpeg");
export const Gallery11 = require("./Assets/gallery11.jpeg");

export const ProductBanner1 = require("./Assets/productBanner.jpg");
export const ProductBanner2 = require("./Assets/productBanner2.jpg");
export const ProductBanner3 = require("./Assets/ProductBanner3.jpg");



