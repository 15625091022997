import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Box, Button } from "@material-ui/core";
import { aboutusHomeImg, Banner2, bg, Wheat_bg } from "../Assets.tsx";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ReactTyped } from "react-typed";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    overflowX: "hidden",
    borderTop: "5px solid black",
    borderBottom: "5px solid black",
    marginTop: "39px"
  },
  aboutUsImage: {
    right: 0,
    top: 0,
  },
  heading: {
    color: "black",
    fontSize: "40pt",
    textAlign: "left",
    marginBottom: "4%",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  historyHeading: {
    color: "white",
    textAlign: "justify",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  text: {
    color: "Black",
    fontSize: "14pt",
    textAlign: "justify",
    marginBottom: "3%",
  },
  historyText: {
    color: "black",
    fontSize: "14pt",
    textAlign: "justify",
  },
  button: {
    backgroundColor: "#1C82C4",
    color: "white",
    width: "210px",
    textAlign: "right",
    padding: "10px",
    fontSize: "14pt",
    boxShadow: "rgb(38, 57, 77) 0px 20px 30px -10px",
    transition: "all 0.5s ease-in-out",
    "&:hover": {
      backgroundColor: "green",
      color: "white",
      boxShadow: "rgba(0, 0, 0, 0.56) 0px 22px 70px 4px",
      transform: "translateY(10px)",
    },
  },
  historyButton: {
    backgroundColor: "#1C82C4",
    color: "white",
  },
}));

const webStyle = {
  orangeBorder: {
    borderTopLeftRadius: "30%",
    borderBottomRightRadius: "30%",
    border: "8px solid orange",
    margin: "10px",
    width: "96.5%",
  },
  whiteBorder: {
    borderTopLeftRadius: "30%",
    borderBottomRightRadius: "30%",
    border: "7px solid white",
  },
  greenBorder: {
    borderTopLeftRadius: "30%",
    borderBottomRightRadius: "30%",
    border: "7px solid green",
  },
};

const AboutUs: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useGSAP(() => {
    if (!isMobile) {
      gsap.from(".gsapAboutText", {
        opacity: 0,
        duration: 2,
        x: -500,
        scrollTrigger: {
          trigger: ".gsapAboutText",
          scroller: "body",
          markers: false,
          start: "top 50%",
        },
      });
    }
  });
  useGSAP(() => {
    if (!isMobile) {
      gsap.from(".gsapVishwasImg", {
        opacity: 0,
        duration: 2,
        x: 500,
        scrollTrigger: {
          trigger: ".gsapVishwasImg",
          scroller: "body",
          markers: false,
          start: "top 50%",
        },
      });
    }
  });

  useGSAP(() => {
    if (!isMobile) {
      gsap.from(".gsapAboutText2", {
        opacity: 0,
        duration: 2,
        x: -500,
        scrollTrigger: {
          trigger: ".gsapAboutText2",
          scroller: "body",
          markers: false,
          start: "top 50%",
        },
      });

    }
  });
  useGSAP(() => {
    if (!isMobile) {
      gsap.from(".gsapVishwasImg2", {
        opacity: 0,
        duration: 2,
        x: 500,
        scrollTrigger: {
          trigger: ".gsapVishwasImg2",
          scroller: "body",
          markers: false,
          start: "top 50%",
        },
      });

    }
  });

  return (
    <div id="aboutus" className={classes.root}>
      <Box
        className="aboutUs-div aboutUsContainerHome"
        style={{
          display: "flex",
          padding: "3% 3%",
          background: `url(${bg}) no-repeat center center/cover`,
          flexDirection: "row-reverse",
          gap: "60px",
          borderBottom: "5px solid black",
          height: "750px",
          alignItems: "center",
        }}
      >
        <Box className="aboutUs-image-div AboutMoreButton">
          <div className="gsapVishwasImg" style={webStyle.orangeBorder}>
            <div style={webStyle.whiteBorder}>
              <div style={webStyle.greenBorder}>
                <img
                  src={aboutusHomeImg}
                  alt="icon"
                  className="aboutUsImage hoverimageName"
                  style={{
                    borderTopLeftRadius: "29%",
                    borderBottomRightRadius: "29%",
                    height: "400px",
                    width: "550px",
                  }}
                />
              </div>
              <div className="hoverNameText">Dr. Vishwas Vitthal Sondkar</div>
            </div>
          </div>
        </Box>
        <Box
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <span
            style={{
              textAlign: "center",
              fontSize: "46px",
              fontWeight: 600,
              color: "#99cc00",
            }}
            className={classes.heading + " gsapAboutText"}
          >
            {t("about.aboutName")}&nbsp;
            <span
              style={{
                color: "#339966",
                fontSize: "26px",
                textDecoration: "underline",
              }}
            >
              <ReactTyped style={{color: "#339966", textDecoration: "underline", fontSize: "26px", fontWeight: 600}} 
              strings={[t("about.aboutNameH")]} typeSpeed={100} loop />
            </span>
          </span>
          <Typography className={classes.text}>{t("about.aboutUs")}</Typography>
          <div className="AboutMoreButton gsapAboutText">
            <Button
              onClick={() => {
                history.push("About-us");
              }}
              className={classes.button}
            >
              {t("about.aboutMore")}
            </Button>
          </div>
        </Box>
      </Box>

      <div
        className="history-div aboutUsContainerHome fixed-bg"
        style={{ display: "flex", margin: " 0 3%" }}
      >
        <div style={{ display: "flex", alignItems: "center" }} className="aboutUsContainerHome">
          <div
            className="hisory-image-div AboutMoreButton fixed-bg-content"
            style={{ width: "100%" }}
          >
            <div className="gsapVishwasImg2" style={webStyle.orangeBorder}>
              <div style={webStyle.whiteBorder}>
                <div style={webStyle.greenBorder}>
                  <img
                    src={Banner2}
                    alt="icon"
                    className="aboutUsImage"
                    style={{
                      borderTopLeftRadius: "29%",
                      borderBottomRightRadius: "29%",
                      height: "400px",
                      width: "100%",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <Box
            className="fixed-bg-content"
            style={{ width: "100%", display: "flex", flexDirection: "column" }}
          >
            <Typography
              variant="h2"
              className={
                `${classes.heading} ${classes.historyHeading}` + "gsapAboutText"
              }
            >
              {/* <ReactTyped strings={[t("about.Name")]} typeSpeed={100} loop /> */}
              <span style={{color: "#99cc00", fontSize: "46px", fontWeight: 600 }}>{t("about.whyUs")} </span>
              <ReactTyped style={{color: "#339966", textDecoration: "underline", fontSize: "26px", fontWeight: 600}} 
              strings={[t("about.whyUsH")]} typeSpeed={100} loop />
            </Typography>
            <Typography className={` ${classes.historyText}`}>
              <ul>
                <li>
                <strong>{t("about.whyusline1")}</strong>
                </li>
                <li>
                  <strong>{t("about.whyusline2")}</strong>
                </li>
                <li>
                  <strong>{t("about.whyusline3")}</strong>
                </li>
                <li>
                  <strong>{t("about.whyusline4")}</strong>
                </li>
                <li>
                  <strong>{t("about.whyusline5")}</strong>
                </li>
                <li>
                  <strong>{t("about.whyusline6")}</strong>
                </li>
                <li>
                  <strong>{t("about.whyusline7")}</strong>
                  <p>{t("about.whyusline8")}</p>
                </li>
              </ul>
            </Typography>
            <div className="AboutMoreButton gsapVishwasImg2">
              <Button
                className={`${classes.button} ${classes.historyButton}`}
                onClick={() => {
                  history.push("About-us");
                }}
              >
                {t("about.history")}
              </Button>
            </div>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
